import { Box, Grid, Typography, Link, IconButton } from '@mui/material';
import { Facebook, X, LinkedIn, Message, ShoppingCart, Store } from '@mui/icons-material';
import { paths } from '../../paths';
import { config } from '../../config';

const Footer = () => {
    return (
        <footer style={{ backgroundColor: '#f8f8f8', paddingTop: '64px', paddingBottom: '48px' }}>
            <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '0 16px' }}>
                {/* Main footer content - Horizontal on desktop, Vertical on mobile */}
                <Grid container spacing={6}>
                    {/* Company Information */}
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" sx={{ marginBottom: '16px' }}>Company</Typography>
                        <Box>
                            <Link href={paths.main.about} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>About Virtacc</Link>
                            <Link href={paths.main.contactUs} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>Contact Us</Link>
                            <Link href={paths.main.termsOfService} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>Terms of Service</Link>
                            <Link href={paths.main.privacyPolicy} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>Privacy Policy</Link>
                        </Box>
                    </Grid>

                    {/* Product & Features */}
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" sx={{ marginBottom: '16px' }}>Product</Typography>
                        <Box>
                            <Link href={paths.main.features} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>Features</Link>
                            <Link href={paths.main.pricing} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>Pricing</Link>
                            <Link href={paths.main.apiDocumentation} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>API Documentation</Link>
                            <Link href={paths.main.successStories} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>Success Stories</Link>
                        </Box>
                    </Grid>

                    {/* Resources */}
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" sx={{ marginBottom: '16px' }}>Resources</Typography>
                        <Box>
                            <Link href={paths.main.helpCenter} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>Help Center</Link>
                            <Link href={paths.main.eCommerceGuide} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>E-commerce Guides</Link>
                            <Link href={paths.main.blog} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>Blog</Link>
                            <Link href={paths.main.faq} sx={{ display: 'block', marginBottom: '8px', color: 'text.primary', '&:hover': { color: 'primary.main' } }}>FAQs</Link>
                        </Box>
                    </Grid>
                </Grid>

                {/* Connect & Integrations Section */}
                <Box sx={{ marginTop: '48px', paddingTop: '32px', borderTop: '1px solid #e0e0e0' }}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={4}>
                        {/* Social Links */}
                        <Grid item>
                            <Typography variant="body1" sx={{ color: 'text.secondary', marginBottom: '16px' }}>Connect with us:</Typography>
                            <Box>
                                <IconButton href={config.socailLinks.facebook} target='blank' sx={{ color: 'text.primary', '&:hover': { color: 'primary.main' } }}>
                                    <Facebook />
                                </IconButton>
                                <IconButton href={config.socailLinks.X} target='blank' sx={{ color: 'text.primary', '&:hover': { color: 'primary.main' } }}>
                                    <X />
                                </IconButton>
                                <IconButton href={config.socailLinks.linkedIn} target='blank' sx={{ color: 'text.primary', '&:hover': { color: 'primary.main' } }}>
                                    <LinkedIn />
                                </IconButton>
                            </Box>
                        </Grid>

                        {/* Integrations */}
                        <Grid item>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', md: { flexDirection: 'row' } }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                    <ShoppingCart sx={{ color: 'text.primary', marginRight: '8px' }} />
                                    <Typography variant="body2" sx={{ color: 'text.primary' }}>Amazon</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                    <Store sx={{ color: 'text.primary', marginRight: '8px' }} />
                                    <Typography variant="body2" sx={{ color: 'text.primary' }}>Shopify</Typography>
                                </Box>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    <span style={{ fontWeight: 'bold' }}>Coming Soon:</span> Flipkart, Meesho
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* Bottom Bar */}
                <Box sx={{ marginTop: '48px', paddingTop: '32px', borderTop: '1px solid #e0e0e0' }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>© 2024 Virtacc. All rights reserved.</Typography>
                        </Grid>
                        <Grid item>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Message sx={{ color: 'primary.main', marginRight: '8px' }} />
                                <Typography variant="body2" sx={{ color: 'text.primary' }}>Need help? Contact support</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </footer>
    );
};

export default Footer;
