import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { UsersType } from "../../../../interfaces/Users";
import { toast } from "react-toastify";
import UserService from "../../../../templates/Dashboard/Settings/api/Users.api";
import { Button, Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";

interface UpdateUserFormProps {
    onSuccess: () => void;
    user: UsersType | null;
}

const UpdateUserForm: FC<UpdateUserFormProps> = ({ onSuccess, user }) => {

    const [formData, setFormData] = useState<UsersType | null>(user);
    const [loading, setLoading] = useState<boolean>(false);

    // useEffect(() => {
    //     if (user) {
    //         setFormData(user);
    //     }
    // }, [user])

    // Handle input field change
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData!,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    // Handle form submission (update subscription)
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!formData) return;

        formData.first_name = '';
        formData.last_name = '';
        delete formData.createdAt;
        delete formData.created_by;
        delete formData.is_deleted;
        delete formData.updatedAt;
        delete formData.updated_by;

        setLoading(true);
        try {
            // Make the API call to update the subscription
            const response = await UserService.updateUser(formData);
            if (response.ok) {
                toast.success('User updated successfully!');
                onSuccess();
            } else {
                toast.error('Failed to update User!');
            }
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Stack sx={{ my: 3 }}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>

                    {/* Name Field */}
                    <TextField
                        name="username"
                        type="text"
                        label="Username"
                        value={formData?.username || ''}
                        onChange={onChange}
                        required
                    />
                    
                    {/* First Name Field */}
                    {/* <TextField
                        name="first_name"
                        type="text"
                        label="First Name"
                        value={formData?.first_name || ''}
                        onChange={onChange}
                        required
                    /> */}

                    {/* Last Name Field */}
                    {/* <TextField
                        name="last_name"
                        type="text"
                        label="Last Name"
                        value={formData?.last_name || ''}
                        onChange={onChange}
                        required
                    /> */}

                    {/* Email Field */}
                    <TextField
                        name="email"
                        type="email"
                        label="Email"
                        value={formData?.email || ''}
                        onChange={onChange}
                        required
                    />

                    {/* Password Field */}
                    <TextField
                        name="password"
                        type="password"
                        label="Password"
                        value={formData?.password || ''}
                        onChange={onChange}
                        required
                    />

                    {/* Mobile Field */}
                    <TextField
                        name="mobile_number"
                        type="number"
                        label="Number"
                        value={formData?.mobile_number || ''}
                        onChange={onChange}
                        required
                    />

                    {/* Active Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData?.is_active}
                                onChange={onChange}
                                name="is_active"
                            />
                        }
                        label="Active"
                    />

                    {/* Submit Button */}
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save'}
                    </Button>
                </Stack>
            </form>
        </Stack>
    );
};

export default UpdateUserForm;