import { Box, Typography, Grid, Container, Button, Paper, Icon } from '@mui/material';
import { Users, Target, TrendingUp, Shield, Calendar } from 'lucide-react';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';
import { paths } from '../../paths';

const AboutPage = () => {
    const contactPage = () => { return window.location.href = paths.main.contactUs }
    return (
        <PageContainer title='About | Virtacc' description='This is About page of Virtacc'>

            <Box sx={{ minHeight: '100vh', backgroundColor: 'background.default' }}>

                {/* Navbar Section */}
                <Navbar backgroundColor='primary.main' />

                {/* Hero Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'white', py: 12 }}>
                    <Container>
                        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
                            Simplifying E-commerce Accounting
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ mt: 2, color: "white" }} >
                            Helping e-commerce businesses make smarter financial decisions with real-time insights and automated accounting solutions.
                        </Typography>
                    </Container>
                </Box>

                {/* Mission Section */}
                <Box sx={{ py: 8, backgroundColor: 'secondary.main' }}>
                    <Container maxWidth="lg">
                        <Box textAlign="center" mb={4}>
                            <Typography variant="h4" gutterBottom>
                                Our Mission
                            </Typography>
                            <Typography variant="body1" color="text.secondary" maxWidth="md" mx="auto">
                                To empower e-commerce entrepreneurs with crystal-clear financial insights,
                                enabling them to focus on growth while we handle the complex accounting.
                            </Typography>
                        </Box>
                    </Container>
                </Box>

                {/* Key Features Grid */}
                <Box sx={{ py: 8 }}>
                    <Container maxWidth="lg">
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Icon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }}><Users /></Icon>
                                    <Typography variant="h6" gutterBottom>
                                        Built for E-commerce
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" align="center">
                                        Specialized tools and features designed specifically for online sellers.
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Icon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }}><Target /></Icon>
                                    <Typography variant="h6" gutterBottom>
                                        Accurate Insights
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" align="center">
                                        Real-time financial data and analytics to make informed decisions.
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Icon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }}><TrendingUp /></Icon>
                                    <Typography variant="h6" gutterBottom>
                                        Growth Focus
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" align="center">
                                        Tools and insights that help you scale your e-commerce business.
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                {/* Timeline Section */}
                <Box sx={{ py: 8, backgroundColor: 'secondary.main' }}>
                    <Container maxWidth="lg">
                        <Typography variant="h4" align="center" gutterBottom>
                            Our Journey
                        </Typography>
                        <Box sx={{ space: 4 }}>
                            <Box display="flex" alignItems="flex-start" mb={4}>
                                <Calendar style={{ fontSize: 30, color: 'primary.main', marginRight: 2 }} />
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        2023
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Launched Virtacc with Amazon integration
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="flex-start">
                                <Calendar style={{ fontSize: 30, color: 'primary.main', marginRight: 2 }} />
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        2024
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Added Shopify integration and expanded services
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>

                {/* Trust Section */}
                <Box sx={{ py: 8 }}>
                    <Container maxWidth="lg">
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Typography variant="h4" gutterBottom>
                                    Why Trust Virtacc?
                                </Typography>
                                <Box>
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <Shield style={{ fontSize: 30, color: 'primary.main', marginRight: 2 }} />
                                        <Typography variant="body1" color="text.secondary">
                                            Enterprise-grade security
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <Shield style={{ fontSize: 30, color: 'primary.main', marginRight: 2 }} />
                                        <Typography variant="body1" color="text.secondary">
                                            99.9% uptime guarantee
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Shield style={{ fontSize: 30, color: 'primary.main', marginRight: 2 }} />
                                        <Typography variant="body1" color="text.secondary">
                                            Dedicated support team
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} container justifyContent="center">
                                <img
                                    src="/assets/images/placeholder/500x300.svg"
                                    alt="Trust illustration 500x300"
                                    style={{ 
                                        borderRadius: 8, 
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        maxWidth: '100%' 
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                {/* CTA Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'white', py: 8 }}>
                    <Container sx={{ maxWidth: "lg", alignContent: "center" }}>
                        <Typography variant="h4" gutterBottom>
                            Ready to streamline your e-commerce accounting?
                        </Typography>
                        <Typography variant="h6" color="primary.contrastText" mb={4}>
                            Join thousands of successful e-commerce businesses using Virtacc
                        </Typography>
                        <Button variant="contained" color="success" onClick={contactPage} size="large">
                            Start Free Trial
                        </Button>
                    </Container>
                </Box>

                {/* Footer Section */}
                <Footer />

            </Box>

        </PageContainer>
    );
};

export default AboutPage;
