import { Star } from 'lucide-react';
import { Button, Card, CardContent, Grid, Typography, Box } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';
import { paths } from '../../paths';
// import OldFooter from './OldFooter';

const LandingPage = () => {
    const metrics = [
        { label: 'Net profit', value: '₹10,960', change: '+113.6%', color: 'green' },
        { label: 'Revenue', value: '₹76,318', change: '+122.4%', color: 'green' },
        { label: 'Orders', value: '51,198', change: '-9.7%', color: 'red' },
        { label: 'Gross profit', value: '₹23,639', change: '+113.6%', color: 'green' }
    ];

    const contactPage = () => { return window.location.href = paths.main.contactUs }

    return (
        <PageContainer title='Home | Virtacc' description='This is Home page of Virtacc'>

            <Box sx={{ minHeight: '100vh', backgroundColor: 'white' }}>

                {/* Navigation */}
                {/* <Navbar backgroundColor='#1E3A8A' /> */}
                <Navbar backgroundColor='primary.main' />

                {/* Hero Section */}
                <Box sx={{ paddingY: 8, paddingX: 4, backgroundColor: 'primary.main' }}>
                    <Box sx={{ textAlign: 'center', maxWidth: 'lg', margin: '0 auto' }}>
                        <Typography variant="h3" sx={{ color: 'primary.contrastText', fontWeight: 'bold', marginBottom: 2 }}>
                            Your Complete E-commerce Accounting Solution
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'grey.200', marginBottom: 4 }}>
                            Track profits, analyze costs, and grow your Amazon business with real-time financial insights
                        </Typography>
                        <Button variant="contained" color="success" onClick={contactPage} sx={{ paddingY: 2, paddingX: 4, fontSize: '1rem' }}>
                            Start Free Trial
                        </Button>
                    </Box>
                </Box>

                {/* Testimonials Section */}
                <Box sx={{ paddingY: 8, paddingX: 4, backgroundColor: 'secondary.main' }}>
                    <Box sx={{ maxWidth: 'lg', margin: '0 auto' }}>
                        <Grid container spacing={4}>
                            {/* Testimonial 1 */}
                            <Grid item xs={12} md={6}>
                                <Card sx={{ padding: 4, boxShadow: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', marginBottom: 2 }}>
                                        {[...Array(5)].map((_, i) => (
                                            <Star key={i} style={{ width: 24, height: 24, color: '#FBBF24' }} />
                                        ))}
                                    </Box>
                                    <Typography variant="h6" sx={{ textAlign: 'center', fontStyle: 'italic', marginBottom: 2 }}>
                                        "Incredible breakdown by cohorts and easy to digest data. Nothing comes close to ease of use, price and support from the team."
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img src="/assets/images/avatars/avatar_15.jpg" alt="Rajesh Kumar" style={{ borderRadius: '50%', width: 48, height: 48 }} />
                                        <Box sx={{ marginLeft: 2 }}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Rajesh Kumar</Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Top Amazon Seller, Electronics Category</Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>

                            {/* Testimonial 2 */}
                            <Grid item xs={12} md={6}>
                                <Card sx={{ padding: 4, boxShadow: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', marginBottom: 2 }}>
                                        {[...Array(5)].map((_, i) => (
                                            <Star key={i} style={{ width: 24, height: 24, color: '#FBBF24' }} />
                                        ))}
                                    </Box>
                                    <Typography variant="h6" sx={{ textAlign: 'center', fontStyle: 'italic', marginBottom: 2 }}>
                                        "The automation of Amazon fee calculations has saved us countless hours. Essential tool for any serious seller."
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img src="/assets/images/avatars/avatar_20.jpg" alt="Priya Sharma" style={{ borderRadius: '50%', width: 48, height: 48 }} />
                                        <Box sx={{ marginLeft: 2 }}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Priya Sharma</Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Founder, HomeStyle India</Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/* Dashboard Preview Section */}
                <Box sx={{ paddingY: 8, paddingX: 4, backgroundColor: 'white' }}>
                    <Box sx={{ maxWidth: 'lg', margin: '0 auto', textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1E3A8A', marginBottom: 4 }}>
                            Unlimited Custom Dashboards
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'text.secondary', marginBottom: 8 }}>
                            Take control of your metric tracking. Monitor KPIs from high-level P&L metrics to detailed marketing campaign data in beautiful, comprehensive dashboards.
                        </Typography>

                        {/* Metrics Grid */}
                        <Grid container spacing={4}>
                            {metrics.map((metric, index) => (
                                <Grid item xs={12} md={3} key={index}>
                                    <Card sx={{ padding: 3, boxShadow: 3 }}>
                                        <CardContent>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>{metric.label}</Typography>
                                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metric.value}</Typography>
                                            <Typography variant="body2" sx={{ color: metric.color }}>
                                                {metric.change}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>

                {/* Call to Action Section */}
                <Box sx={{ paddingY: 8, paddingX: 4, backgroundColor: 'primary.main', color: 'primary.contrastText' }}>
                    <Box sx={{ textAlign: 'center', maxWidth: 'lg', margin: '0 auto' }}>
                        <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                            Ready to Take Control of Your Finances?
                        </Typography>
                        <Typography variant="h6" sx={{ marginBottom: 4 }}>
                            Join successful Indian sellers who trust Virtacc with their financial analytics
                        </Typography>
                        <Button variant="contained" color="success" onClick={contactPage} sx={{ paddingY: 2, paddingX: 4, fontSize: '1rem' }}>
                            Start Free Trial
                        </Button>
                    </Box>
                </Box>

                {/* Footer */}
                <Footer />
                {/* <OldFooter /> */}

            </Box>

        </PageContainer>
    );
};

export default LandingPage;
