import { Button, Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { UsersType } from "../../../../interfaces/Users";
import UserService from "../../../../templates/Dashboard/Settings/api/Users.api";
import { toast } from "react-toastify";
import { Helper } from "../../../../utils/Helper";
// import { userDataPayload } from "../../../../interfaces/UserPayload";

interface AddUserFormProps {
    onSuccess: () => void;
}

const AddUserForm: React.FC<AddUserFormProps> = ({ onSuccess }) => {

    useEffect(() => {
        const user = Helper.getCurrentUser();
        // setCurrentUser(user);
        if (user) setCurrentOrg(user.organization);
    }, [])

    const [loading, setLoading] = useState<boolean>(false);
    // const [currentUser, setCurrentUser] = useState<userDataPayload | null>(null);
    const [currentOrg, setCurrentOrg] = useState<string>('');
    const [formData, setFormData] = useState<UsersType>({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        mobile_number: '',
        role: '',
        organization: currentOrg,  // Set the initial organization
        is_active: true,
        is_deleted: false
    });

    // Update formData when currentOrg changes
    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            organization: currentOrg  // Ensure organization is included in formData
        }));
    }, [currentOrg]);

    // Handle input field change
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData!,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    // Handle form submission (update subscription)
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!formData) return;

        setLoading(true);
        try {
            // Make the API call to update the subscription
            const response = await UserService.createUser(formData);
            if (response.ok) {
                toast.success('User Added successfully!');
                onSuccess();
            } else {
                toast.error('Failed to add User!');
            }
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Stack sx={{ my: 3 }}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>

                    {/* Name Field */}
                    <TextField
                        name="username"
                        type="text"
                        label="Username"
                        value={formData?.username || ''}
                        onChange={onChange}
                        required
                    />

                    {/* First Name Field */}
                    {/* <TextField
                        name="first_name"
                        type="text"
                        label="First Name"
                        value={formData?.first_name || ''}
                        onChange={onChange}
                        required
                    /> */}

                    {/* Last Name Field */}
                    {/* <TextField
                        name="last_name"
                        type="text"
                        label="Last Name"
                        value={formData?.last_name || ''}
                        onChange={onChange}
                        required
                    /> */}

                    {/* Email Field */}
                    <TextField
                        name="email"
                        type="email"
                        label="Email"
                        value={formData?.email || ''}
                        onChange={onChange}
                        required
                    />

                    {/* Password Field */}
                    <TextField
                        name="password"
                        type="password"
                        label="Password"
                        value={formData?.password || ''}
                        onChange={onChange}
                        required
                    />

                    {/* Mobile Field */}
                    <TextField
                        name="mobile_number"
                        type="number"
                        label="Number"
                        value={formData?.mobile_number || ''}
                        onChange={onChange}
                        required
                    />

                    {/* Role Field */}
                    <TextField
                        name="role"
                        type="text"
                        label="Role"
                        value={formData?.role || ''}
                        onChange={onChange}
                        required
                    />

                    {/* Organization Field */}
                    <TextField
                        name="organization"
                        type="text"
                        label="Organization"
                        value={currentOrg}
                        // InputProps={{ readOnly: true }}
                        disabled
                        required
                    />

                    {/* Active Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData?.is_active}
                                onChange={onChange}
                                name="is_active"
                            />
                        }
                        label="Active"
                    />

                    {/* Delete Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData?.is_deleted}
                                onChange={onChange}
                                name="is_deleted"
                            />
                        }
                        label="Delete"
                    />

                    {/* Submit Button */}
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save'}
                    </Button>
                </Stack>
            </form>
        </Stack>
    );
};

export default AddUserForm;