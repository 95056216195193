import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ppcType } from '../../../../interfaces/PPC';
import dayjs from 'dayjs'; // Import dayjs for date formatting
import { Card, CardContent } from '@mui/material';
import { formatNumber } from '../../../../utils/format';

interface LLineChartProps {
    ppcData: ppcType[]; // Array of ppcType data to pass to the chart
}

const LLineChart: React.FC<LLineChartProps> = ({ ppcData = [] }) => {

    // Check if ppcData is an array and proceed only if it is
    const chartData = Array.isArray(ppcData) && ppcData.length > 0
        // Reduce the ppcData to combine values for each date
        ? ppcData.reduce((acc, item) => {
            const formattedDate = dayjs(item.date).format('DD MMM'); // Format date as "DD MMM"
            const existing = acc.find((data) => data.date === formattedDate);
            if (existing) {
                // Accumulate values for existing dates
                existing.spend += formatNumber(item.spend || 0);
                existing.clicks += item.clicks;
                existing.sales7d += formatNumber(item.sales7d || 0);
                existing.impressions += item.impressions;
                existing.roasClicks14d += formatNumber(item.roasClicks14d || 0);
                existing.costPerClick += formatNumber(item.costPerClick || 0);
            } else {
                // Create a new entry for the date
                acc.push({
                    date: formattedDate,
                    spend: formatNumber(item.spend || 0),
                    clicks: item.clicks,
                    sales7d: formatNumber(item.sales7d || 0),
                    impressions: item.impressions,
                    roasClicks14d: formatNumber(item.roasClicks14d || 0),
                    costPerClick: formatNumber(item.costPerClick || 0),
                });
            }
            return acc;
        }, [] as {
            date: string;
            spend: number;
            clicks: number;
            sales7d: number;
            impressions: number;
            roasClicks14d: number;
            costPerClick: number;
        }[])
        : [];

    return (
        <Card>
            <CardContent>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {/* Add different lines for various metrics */}
                        <Line type="monotone" dataKey="spend" stroke="#8884d8" />
                        <Line type="monotone" dataKey="clicks" stroke="#82ca9d" />
                        <Line type="monotone" dataKey="sales7d" stroke="#ff7300" />
                        {/* <Line type="monotone" dataKey="purchases7d" stroke="#ff0000" /> */}
                        <Line type="monotone" dataKey="impressions" stroke="#00bcd4" />
                        <Line type="monotone" dataKey="roasClicks14d" stroke="#9c27b0" />
                        <Line type="monotone" dataKey="costPerClick" stroke="#009688" />
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>

    );
};

export default LLineChart;
