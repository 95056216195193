import { ChangeEvent, FormEvent, useState } from 'react';
import { MapPin, Phone, Mail, Send } from 'lucide-react';
import {
    Box,
    Container,
    Typography,
    Grid,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Card,
    SelectChangeEvent
} from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';
import { contactForm } from '../../interfaces/SendMail';
import mailService from './api/SendMail.api';
import { toast } from 'react-toastify';
import { config } from '../../config';

const ContactPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<contactForm>({
        name: '',
        email: '',
        company: '',
        message: '',
        type: 'support'
    });

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const onSelectChange = (event: SelectChangeEvent<string>) => {
        setFormData(prevData => ({ ...prevData, type: event.target.value })); // Update formData with the selected organization
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await mailService.sendMailContactForm(formData);
            if (response.data) {
                toast.success(response.message);
                setFormData({
                    name: '', email: '', company: '', message: '', type: 'support',
                }); // Clear the form data
            } else {
                toast.error(response.message);
                setFormData({
                    name: '', email: '', company: '', message: '', type: 'support',
                }); // Clear the form data
            }
            setLoading(false);
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
            setLoading(false);
        }
    };

    return (
        <PageContainer title='Contact Us | Virtacc' description='This is Contact Us page of Virtacc'>

            <Box sx={{ minHeight: '100vh', backgroundColor: 'white' }}>

                {/* Navbar Section */}
                <Navbar backgroundColor='primary.main' />

                {/* Hero Section */}
                <Box sx={{ backgroundColor: 'primary.main', py: 10 }}>
                    <Container>
                        <Typography variant="h3" align="center" sx={{ color: 'primary.contrastText', fontWeight: 'bold', mb: 6 }}>
                            Get in Touch
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ color: 'primary.contrastText', maxWidth: 900, mx: 'auto' }}>
                            We're here to help you succeed in your e-commerce journey
                        </Typography>
                    </Container>
                </Box>

                {/* Contact Options */}
                <Container sx={{ py: 16 }}>
                    <Grid container spacing={8} justifyContent="center" sx={{ mb: 16 }}>
                        {/* Call Us */}
                        <Grid item xs={12} md={4} textAlign="center">
                            <Card sx={{ padding: 6, border: '1px solid', borderColor: 'secondary.dark', borderRadius: 2, boxShadow: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Phone style={{ fontSize: 48, color: 'primary.main', marginBottom: 4 }} />
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                    Call Us
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{config.support.number}</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{config.support.time}</Typography>
                            </Card>

                        </Grid>

                        {/* Email Us */}
                        <Grid item xs={12} md={4} textAlign="center">
                            <Card sx={{ padding: 6, border: '1px solid', borderColor: 'secondary.dark', borderRadius: 2, boxShadow: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Mail style={{ fontSize: 48, color: 'primary.main', marginBottom: 4 }} />
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                    Email Us
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{config.support.email}</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>24x7 Support</Typography>
                            </Card>
                        </Grid>

                        {/* Visit Us */}
                        <Grid item xs={12} md={4} textAlign="center">
                            <Card sx={{ padding: 6, border: '1px solid', borderColor: 'secondary.dark', borderRadius: 2, boxShadow: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <MapPin style={{ fontSize: 48, color: 'primary.main', marginBottom: 4 }} />
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                    Visit Us
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{config.address.line1}</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{config.address.line2}</Typography>
                            </Card>
                        </Grid>
                    </Grid>

                    {/* Contact Form */}
                    <Container sx={{ maxWidth: 'md' }}>
                        <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', mb: 8 }}>
                            Send us a Message
                        </Typography>
                        <form
                            onSubmit={handleSubmit}
                        // noValidate
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name='name'
                                        label="Name"
                                        value={formData.name}
                                        onChange={onChange}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name='email'
                                        label="Email"
                                        type="email"
                                        value={formData.email}
                                        onChange={onChange}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        name='company'
                                        label="Company"
                                        value={formData.company}
                                        onChange={onChange}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth required>
                                        <InputLabel>Type of Inquiry</InputLabel>
                                        <Select
                                            value={formData.type}
                                            onChange={onSelectChange}
                                            label="Type of Inquiry"
                                            required
                                        >
                                            <MenuItem value="support">Technical Support</MenuItem>
                                            <MenuItem value="sales">Sales Inquiry</MenuItem>
                                            <MenuItem value="partnership">Partnership</MenuItem>
                                            <MenuItem value="other">Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        name='message'
                                        label="Message"
                                        multiline
                                        rows={4}
                                        value={formData.message}
                                        onChange={onChange}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="success"
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        disabled={loading}
                                    >
                                        <Send style={{ marginRight: 2 }} />
                                        Send Message
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>
                </Container>

                {/* Footer Section */}
                <Footer />

            </Box>

        </PageContainer>
    );
};

export default ContactPage;
