import { RazorpayOrderResponse } from "../../../interfaces/Razorpay/Razorpay";
import { CustomFetch } from "../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const callApi = async (amount: number) => {
    console.log('Amount to pay: ', amount);
    return {
        success: true,
        data: 'Amount to pay: ' + amount,
        message: 'Amount to pay: ' + amount
    }
}

const createOrder = async (amount: number) => {
    try {
        const response = await CustomFetch(API_URL + '/payments/razorpay/order', {
            method: 'POST',
            body: JSON.stringify({ amount: amount })
        })
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error creating order:', error);
    }
}

const openRazorpayCheckout = async (order: RazorpayOrderResponse) => {
    const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // key_id
        amount: order.amount,
        currency: 'INR',
        name: 'company name',
        description: 'payment for order',
        order_id: order.id,
        handler: async function (response: any) {
            console.log('response: ', response);
            const data = {
                orderCreationId: order.id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };
            const verifySignResponse = await CustomFetch(API_URL + '/payments/razorpay/verify', {
                method: 'POST',
                body: JSON.stringify(data)
            });
            const verifySign = await verifySignResponse.json();
            alert(verifySign.data.message)
        },
        prefill: {
            name: 'John Doe',
            email: 'mail@mail.com',
            contact: '9876543210',
        },
        theme: {
            color: '#F37254'
        }
    }
    // const rap1 = new Razorpay(options);
    // rap1.open();
}

const paymentService = {
    callApi,
    createOrder,
    openRazorpayCheckout
}
export default paymentService;