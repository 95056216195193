import { CustomFetch } from "../../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const getSubscriptions = async () => {
    const response = await CustomFetch(API_URL + '/subscription', { method: 'GET'});
    return response.json();
}

// const createSubscriptions = async (
//     organization: string, no_of_users: number, starts_at: string, expire_at: string
// ) => {
//     return await CustomFetch(API_URL + '/subscription', {
//         method: 'POST', 
//         body: JSON.stringify({
//             organization, no_of_users, starts_at, expire_at
//         })
//     });
// }

const subscriptionService = {
    getSubscriptions,
    // createSubscriptions
}

export default subscriptionService;