import * as React from 'react';
import Layout from '../Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';
import { Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import ProductsTable from '../../../components/Dashboard/Products/Products';
import ProductService from './api/Products.api';
import { ProductsType } from '../../../interfaces/Products';
import AddIcon from '@mui/icons-material/Add';
import { Close } from "@mui/icons-material";
import AddProductForm from './AddProductForm';
import { toast } from 'react-toastify';

const ProductsDashboard: React.FC = () => {
  const [productsData, setProductsData] = React.useState<ProductsType[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  React.useEffect(() => {
    fetchProductsFromDB();
  }, [])

  const fetchProductsFromDB = async () => {
    let products = await ProductService.getAllProducts();
    if (products.data) setProductsData(products.data);
    else toast.warn(products.message)
  }

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const handleFormSuccess = () => {
    handleModalClose(); // Close the modal
    fetchProductsFromDB();  // Refresh the organization data
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid var(--mui-palette-primary-main)',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
  };

  const renderModal = (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-add-organization"
      aria-describedby="modal-add-organization-description"
    >
      <Stack sx={style} >
        <Stack direction="row" spacing={3}>
          <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
            <Typography sx={{ mb: 3 }} variant="h5">Add Organization</Typography>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{ position: 'absolute', right: 20 }}
          >
            <Close />
          </IconButton>
        </Stack>
        <AddProductForm onSuccess={handleFormSuccess} />
      </Stack>
    </Modal>
  );

  return (
    <PageContainer title={'Products | Dashboard | ' + config.site.name} description='This is Products page of Virtacc'>
      <Layout>
        {/* <Layout title='Products'> */}
        <Stack spacing={3}>
          <Stack direction="row" spacing={3}>
            <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
              <Typography variant="h4">Products</Typography>
            </Stack>
            <Button onClick={handleModalOpen} startIcon={<AddIcon />} variant="contained">
              Add
            </Button>
            {renderModal}
          </Stack>
          <ProductsTable products={productsData} sx={{ height: '100%' }} />
        </Stack>
      </Layout>
    </PageContainer>
  );
}

export default ProductsDashboard
