import React, { useState } from 'react';
import {
    Search,
    ChevronDown,
    ChevronUp,
    MessageCircle
} from 'lucide-react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Typography,
    TextField,
    Box,
    Container
} from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';

type FAQ = {
    q: string;
    a: string;
};

type FAQSection = {
    title: string;
    questions: FAQ[];
};

const FAQPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [openSection, setOpenSection] = useState<string>('general');
    const [openQuestions, setOpenQuestions] = useState<{ [key: number]: boolean }>({});

    const faqSections: { [key: string]: FAQSection } = {
        general: {
            title: "General Questions",
            questions: [
                {
                    q: "What is Virtacc?",
                    a: "Virtacc is a complete e-commerce accounting solution that helps online sellers track profits, analyze costs, and manage their Amazon and Shopify businesses with real-time financial insights."
                },
                {
                    q: "How do I get started with Virtacc?",
                    a: "Getting started is easy! Sign up for a free trial, connect your e-commerce platforms (Amazon/Shopify), and start tracking your financial data. Our onboarding team will guide you through the setup process."
                },
                {
                    q: "What platforms do you support?",
                    a: "We currently support Amazon and Shopify. Integration with Flipkart and Meesho is coming soon. We're continuously adding support for more platforms based on user demand."
                }
            ]
        },
        billing: {
            title: "Billing & Subscription",
            questions: [
                {
                    q: "What are your pricing plans?",
                    a: "We offer two main plans: Growth (₹999/month) for up to 300 orders/month with email support, and Enterprise (₹2,499/month) for unlimited orders with priority support."
                },
                {
                    q: "Can I change my plan later?",
                    a: "Yes, you can upgrade or downgrade your plan at any time. Changes will take effect in your next billing cycle."
                },
                {
                    q: "Do you offer refunds?",
                    a: "We offer a 14-day money-back guarantee if you're not satisfied with our service. Contact our support team for refund requests."
                }
            ]
        },
        features: {
            title: "Features & Integration",
            questions: [
                {
                    q: "How does the Amazon fee calculation work?",
                    a: "Virtacc automatically fetches and categorizes all Amazon fees, including referral fees, FBA fees, and promotional fees. Our system provides detailed breakdowns and reconciliation reports."
                },
                {
                    q: "Can I export my financial reports?",
                    a: "Yes, you can export reports in multiple formats including PDF, Excel, and CSV. Custom report formats are available for Enterprise plan users."
                },
                {
                    q: "How often is the data updated?",
                    a: "Data is synchronized in real-time for most metrics. Some detailed reports may update hourly or daily depending on the data type and platform limitations."
                }
            ]
        },
        security: {
            title: "Security & Privacy",
            questions: [
                {
                    q: "How do you protect my data?",
                    a: "We use bank-grade encryption for all data storage and transmission. Our systems are regularly audited and comply with industry security standards."
                },
                {
                    q: "Who has access to my financial data?",
                    a: "Only you and your authorized team members can access your data. Our support team may access your account for troubleshooting only with your permission."
                }
            ]
        },
        support: {
            title: "Support & Training",
            questions: [
                {
                    q: "What kind of support do you offer?",
                    a: "Growth plan includes 24/7 email support. Enterprise plan users get priority phone support and a dedicated account manager."
                },
                {
                    q: "Do you provide training?",
                    a: "Yes, we offer free onboarding sessions, video tutorials, and documentation. Enterprise users get access to personalized training sessions."
                }
            ]
        }
    };

    const toggleQuestion = (id: number) => {
        setOpenQuestions(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return (
        <PageContainer title='FAQs | Virtacc' description='This is FAQs page of Virtacc'>

            <Box sx={{ minHeight: '100vh', backgroundColor: 'primary.contrastText' }}>

                {/* Navbar Section */}
                <Navbar backgroundColor='primary.main' />

                {/* Hero Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 10, textAlign: 'center' }}>
                    <Container>
                        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Frequently Asked Questions
                        </Typography>
                        <Box sx={{ maxWidth: 500, mx: 'auto', borderRadius: 2 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Search FAQ..."
                                value={searchQuery}
                                color='primary'
                                onChange={(e) => setSearchQuery(e.target.value)}
                                sx={{
                                    backgroundColor: 'primary.contrastText',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                    },
                                    '& .MuiInputBase-input': {
                                        paddingLeft: 3,
                                        paddingRight: 3
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <Search style={{ position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)', color: 'secondary.dark' }} />
                                    )
                                }}
                            />
                        </Box>
                    </Container>
                </Box>

                {/* FAQ Content */}
                <Box sx={{ maxWidth: 960, margin: '0 auto', py: 8 }}>
                    <Container>
                        {/* Section Navigation */}
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 6 }}>
                            {Object.entries(faqSections).map(([key, section]) => (
                                <Button
                                    key={key}
                                    variant={openSection === key ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => setOpenSection(key)}
                                    sx={{
                                        borderRadius: 20,
                                        textTransform: 'none',
                                        padding: '8px 16px',
                                        ...(openSection === key && {
                                            backgroundColor: 'primary.main',
                                            color: 'primary.contrastText'
                                        })
                                    }}
                                >
                                    {section.title}
                                </Button>
                            ))}
                        </Box>

                        {/* FAQ Accordions */}
                        <Box>
                            {faqSections[openSection].questions.map((faq, index) => (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={
                                            openQuestions[index] ? <ChevronUp /> : <ChevronDown />
                                        }
                                        aria-controls={`panel-${index}-content`}
                                        id={`panel-${index}-header`}
                                        onClick={() => toggleQuestion(index)}
                                    >
                                        <Typography sx={{ fontWeight: 'bold' }}>{faq.q}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{faq.a}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Container>
                </Box>

                {/* Still Need Help Section */}
                <Box sx={{ backgroundColor: 'secondary.dark', py: 8, textAlign: 'center' }}>
                    <Container>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Still Need Help?
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'secondary.contrastText', mb: 4 }}>
                            Contact our support team for personalized assistance
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<MessageCircle />}
                                sx={{ padding: '10px 20px' }}
                            >
                                Contact Support
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ padding: '10px 20px', background: 'white' }}
                            >
                                Schedule a Demo
                            </Button>
                        </Box>
                    </Container>
                </Box>

                {/* Footer Section */}
                <Footer />

            </Box>

        </PageContainer>
    );
};

export default FAQPage;
