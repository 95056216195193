
export interface Config {
  site: { name: string; description: string; };
  support: { number: string, email: string, time: string },
  legalMail: string,
  privacyMail: string,
  address: { line1: string, line2: string, full: string },
  grievanvceOfficer: { name: string, email: string }
  socailLinks: { facebook: string, X: string, linkedIn: string }
}

export const config: Config = {
  site: {
    name: 'Virtacc',
    description: ''
  },
  support: {
    number: '+91 9667030096',
    email: 'support@virtacc.com',
    time: 'Mon-Sat, 11am-6pm IST'
  },
  legalMail: 'legal@virtacc.com',
  privacyMail: 'privacy@virtacc.com',
  address: {
    line1: 'E 96, Block E, Kalkaji',
    line2: 'Delhi 110019',
    full: 'E 96, Block E, Kalkaji, Delhi 110019'
  },
  grievanvceOfficer: {
    name: 'Mukul Sharma',
    email: 'support@virtacc.com'
  },
  socailLinks: {
    facebook: 'https://www.facebook.com/',
    X: 'https://x.com/',
    linkedIn: 'https://linkedin.com/'
  }
};
