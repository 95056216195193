import { Box, Button } from "@mui/material";
// import { BarChart3 } from "lucide-react";
import { paths } from "../../paths";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Link } from "react-router-dom";
import DynamicLogo from "../../components/Core/Logo";
import { userDataPayload } from "../../interfaces/UserPayload";
import { Helper } from "../../utils/Helper";

interface NavbarProps {
    backgroundColor: string;
}

const Navbar: React.FC<NavbarProps> = ({ backgroundColor }) => {
    const [user, setUser] = React.useState<userDataPayload | null>(null);
    React.useEffect(() => {
        const currentUser: userDataPayload | null = Helper.getCurrentUser();
        setUser(currentUser);
    }, [])
    const toSignIn = () => { return window.location.href = paths.auth.signIn; }
    const toDashboard = () => { return window.location.href = paths.dashboard.overview }
    return (
        <Box sx={{ backgroundColor: backgroundColor, padding: 2 }}>
            <nav>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Box component={Link} to={paths.home} sx={{ display: 'flex', alignItems: 'center', color: 'primary.contrastText' }}>
                        {/* <BarChart3 style={{ width: 32, height: 32, marginRight: 8, color: 'primary.contrastText' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Virtacc
                        </Typography> */}
                        <DynamicLogo colorLight="light" height={40} width={133} />
                    </Box>
                    {
                        user && (
                            <Button onClick={toDashboard} variant="outlined" sx={{ borderColor: 'primary.contrastText', color: 'primary.contrastText', '&:hover': { backgroundColor: 'secondary.light', color: 'primary.main' } }}>
                                Dashboard
                            </Button>
                        )
                    }
                    {
                        !user && (
                            <Button onClick={toSignIn} variant="outlined" sx={{ borderColor: 'primary.contrastText', color: 'primary.contrastText', '&:hover': { backgroundColor: 'secondary.light', color: 'primary.main' } }}>
                                Login
                            </Button>
                        )
                    }

                </Grid>
            </nav>
        </Box>
    );
};

export default Navbar;