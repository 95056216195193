import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import { toast } from "react-toastify";
import paymentService from "./Payment.api";

const PaymentPage: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState({ amount: 0 });
    // const [order, setOrder] = useState<{ id: string, amount: number, receipt: string, currency: 'INR' }|null>(null);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            const orderData = await paymentService.createOrder(formData.amount);
            console.log('Response Data: ');
            console.log(orderData);
            const resp = await paymentService.openRazorpayCheckout(orderData)
            setLoading(false);
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
            setLoading(false);
        }
    };

    return (
        <Box sx={{ backgroundColor: 'secondary.main', py: 6 }}>
            <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Razorpay Payment
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
                    Razorpay Payment Gateway Integration
                </Typography>
                <form onSubmit={handleSubmit} >
                    <Box sx={{ display: 'flex', flexDirection: 'column', sm: 'row', gap: 2, justifyContent: 'center' }}>
                        <TextField
                            name="amount"
                            label="Amount"
                            variant="outlined"
                            placeholder="Amount (INR)"
                            fullWidth
                            sx={{ borderRadius: 2 }}
                            onChange={onChange}
                        />
                        <Button type='submit' variant="contained" color="success" sx={{ px: 4, py: 2 }} disabled={loading}>
                            Pay Now
                        </Button>
                    </Box>
                </form>

            </Container>
        </Box>
    )
}
export default PaymentPage;