import * as React from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import dayjs from 'dayjs';
import { IntegrationData } from '../../../../interfaces/Integration';

export interface IntegrationDataProps {
  sx?: SxProps;
  channels?: IntegrationData[];
}

function changeStatus() {
  // console.log('clicked');
}

const ChannelsTable: React.FC<IntegrationDataProps> = ({ channels = [], sx }) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Handle Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const paginatedChannel = channels.length > 0 ? channels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : []

  return (
    <Card sx={sx}>
      <Box sx={{ overflowX: 'auto' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Channel</TableCell>
              <TableCell>API Type</TableCell>
              {/* <TableCell>Access Token</TableCell> */}
              {/* <TableCell>Refresh Token</TableCell> */}
              {/* <TableCell>Client ID</TableCell> */}
              <TableCell>Date Added</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedChannel.length > 0 ? (
              paginatedChannel.map((row) => (
                <TableRow hover key={row.organization}>
                  <TableCell>{row.channel}</TableCell>
                  <TableCell>{row.api_type}</TableCell>
                  {/* <TableCell>{row.access_token}</TableCell> */}
                  {/* <TableCell>{row.refresh_token}</TableCell> */}
                  {/* <TableCell>{row.client_id}</TableCell> */}
                  <TableCell>{dayjs(row.createdAt).format('MMM D, YYYY')}</TableCell>
                  <TableCell>
                    <Chip component={Button} onClick={changeStatus} color='success' label='Active' />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <TablePagination 
        component="div"
        count={paginatedChannel.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

export default ChannelsTable;