import * as React from 'react';
import { Avatar, Button, Card, CardContent, CardActions, Divider, Stack, Typography } from '@mui/material';
import { Helper } from '../../../../utils/Helper';
import { userDataPayload } from '../../../../interfaces/UserPayload';

// const user = {
//     name: 'Sofia Rivers',
//     avatar: '/assets/images/avatars/avatar_1.jpg',
//     jobTitle: 'Senior Developer',
//     country: 'USA',
//     city: 'Los Angeles',
//     timezone: 'GTM-7',
// } as const;

const AccountInfo: React.FC = () => {
    const [user, setUser] = React.useState<userDataPayload | null>(null);

    React.useEffect(() => {
        const currentUser: userDataPayload | null = Helper.getCurrentUser();
        setUser(currentUser);
    }, []);
    
    return (
        <Card>
            <CardContent>
                <Stack spacing={2} sx={{ alignItems: 'center' }}>
                    <div>
                        <Avatar src={'/assets/images/avatars/avatar_1.jpg'} sx={{ height: '80px', width: '80px' }} />
                    </div>
                    <Stack spacing={1} sx={{ textAlign: 'center' }}>
                        <Typography variant="h5">{user?.username}</Typography>
                        <Typography color="text.secondary" variant="body2">ID: {user?.email}</Typography>
                        <Typography color="text.secondary" variant="body2">Organization: {user?.organization}</Typography>
                        <Typography color="text.secondary" variant="body2">Role: {(user?.role === '1')? 'Admin': 'User'}</Typography>
                    </Stack>
                </Stack>
            </CardContent>
            <Divider />
            <CardActions>
                <Button fullWidth variant="text">
                    Upload picture
                </Button>
            </CardActions>
        </Card>
    );
}

export default AccountInfo;