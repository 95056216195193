import { jwtDecode } from "jwt-decode";
import { userDataPayload } from "../interfaces/UserPayload";

export class Helper {

    public static decodeToken(token: string) {
        try {
            const userData = jwtDecode<userDataPayload>(token);
            return userData;
        } catch (err) {
            console.log('Token verification failed: ', err);
            return null;
        }
    }

    public static setStorageData(key: string, data: any): void {
        window.localStorage.setItem(key, data);
    }

    public static getStorageData(key: string) {
        return window.localStorage.getItem(key);
    }

    public static removeStorageData(key: string): void {
        window.localStorage.removeItem(key);
    }

    public static getCurrentUser() {
        const token = this.getStorageData('userToken');
        if (token) {
            const userData = this.decodeToken(token);
            if ( userData ) return userData
            return null
        }
        return null
    }
}
