import * as React from 'react';
// import type { Metadata } from 'next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Layout from '../Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';
import SubscriptionsTable from '../../../components/Dashboard/Settings/Subscriptions/Subscriptions';
import { SubscriptionsType } from '../../../interfaces/Subscriptions';
import subscriptionService from './api/Subscriptions.api';

const SubscriptionsSellers: React.FC = () => {
    const [subscriptions, setSubscriptions] = React.useState<SubscriptionsType[]>([]);
    const getSubscriptions = async () => {
        let subscriptionsData = await subscriptionService.getSubscriptions();
        if (subscriptionsData) setSubscriptions(subscriptionsData);
    }
    React.useEffect(() => {
        getSubscriptions();
    }, [])
    return (
        <PageContainer title={'Subscriptions | Dashboard | ' + config.site.name} description='This is Subscriptions page of Virtacc'>
            <Layout>
                <Stack spacing={3}>
                    <Stack direction="row" spacing={3}>
                        <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
                            <Typography variant="h4">Subscriptions</Typography>
                        </Stack>
                    </Stack>
                    <SubscriptionsTable
                        subscriptions={subscriptions}
                        sx={{ height: '100%' }}
                    />
                </Stack>
            </Layout>
        </PageContainer>
    );
}

export default SubscriptionsSellers
