import { AdsTokenObjType } from "../../../../interfaces/Amazon/TokenObj";
import { CustomFetch } from "../../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const retrieveAmazonSPAPITokens = async (code: string | null) => {
    const response = await CustomFetch(API_URL + '/amazon/sp-api/token', {
        method: 'POST',
        body: JSON.stringify({code: code})
    });
    const responseData = await response.json();
    console.log('reponse data from api')
    console.log(responseData.data);
    // Send data to server
    if (responseData.data.refresh_token) {
        const respSave = await sendARTokenToServer(responseData.data, 'amazon');
        console.log('response data from saving token');
        console.log(respSave);
    }
    return responseData.data;
}

const retrieveAmazonAdAPITokens = async (code: string | null) => {
    const response = await CustomFetch(API_URL + '/ads/ad-api/token', {
        method: 'POST',
        body: JSON.stringify({code: code})
    });
    const responseData = await response.json();
    console.log('response data from api');
    console.log(responseData.data);
    // Send data to server
    if (responseData.data.refresh_token) {
        const respSave = await sendARTokenToServer(responseData.data, 'ads');
        console.log('response data from saving token');
        console.log(respSave);
    }
    return responseData.data;
}

const sendARTokenToServer = async (responseData: AdsTokenObjType, type: string) => {
    const response = await CustomFetch(API_URL + `/${type}/save-token`, {
        method: 'POST',
        body: JSON.stringify(responseData),
    });
    return await response.json();
}

const verifyService = {
    retrieveAmazonAdAPITokens,
    retrieveAmazonSPAPITokens
}

export default verifyService;