import React, { useState } from 'react';
import { Box, Container, Typography, Button, Grid, Card, CardContent, Paper, Divider } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';

interface Endpoint {
    method: string;
    endpoint: string;
    description: string;
    parameters: string;
    response: string;
}

interface Endpoints {
    [key: string]: Endpoint[];
}

const APIDocumentation: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>('authentication');

    const endpoints: Endpoints = {
        "Orders": [
            {
                method: 'GET',
                endpoint: '/api/v1/orders',
                description: 'Retrieve all orders',
                parameters: 'page, limit, dateFrom, dateTo',
                response: JSON.stringify(
                    {
                        "orders": [
                            {
                                "orderId": "ORD123",
                                "amount": 1499.00,
                                "status": "completed",
                                "createdAt": "2024-12-20T10:30:00Z"
                            }
                        ],
                        "totalCount": 100,
                        "page": 1
                    }, null, 2
                )
            },
            {
                method: 'GET',
                endpoint: '/api/v1/orders/{orderId}',
                description: 'Get order details',
                parameters: 'orderId (path)',
                response: JSON.stringify(
                    {
                        "orderId": "ORD123",
                        "customerName": "Rahul Kumar",
                        "amount": 1499.00,
                        "items": [
                            { "item1": {} },
                            { "item2": {} },
                        ],
                        "status": "completed"
                    }, null, 2
                )
            }
        ],
        "Products": [
            {
                method: 'GET',
                endpoint: '/api/v1/products',
                description: 'List all products',
                parameters: 'page, limit, category',
                response: JSON.stringify(
                    {
                        "products": [
                            {
                                "productId": "PROD456",
                                "name": "Premium Widget",
                                "price": 999.00,
                                "stock": 50
                            }
                        ]
                    }, null, 2
                )
            }
        ],
        "Analytics": [
            {
                method: 'GET',
                endpoint: '/api/v1/analytics/sales',
                description: 'Get sales analytics',
                parameters: 'startDate, endDate, groupBy',
                response: JSON.stringify(
                    {
                        "totalSales": 150000,
                        "periodWiseData": [{}, {}],
                        "topProducts": [{}, {}]
                    }, null, 2
                )
            }
        ]
    };

    return (
        <PageContainer title='API Documentation | Virtacc' description='This is API Documentation page of Virtacc'>

            <Box sx={{ minHeight: '100vh', backgroundColor: 'primary.contrastText' }}>

                {/* Navbar Section */}
                <Navbar backgroundColor='primary.main' />

                {/* Hero Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 12 }}>
                    <Container>
                        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
                            API Documentation
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ color: 'primary.contrastText', maxWidth: 900, mx: 'auto' }}>
                            Integrate Virtacc's powerful e-commerce accounting features into your applications
                        </Typography>
                    </Container>
                </Box>

                {/* Main Documentation */}
                <Container sx={{ py: 8 }}>
                    <Grid container spacing={4}>
                        {/* Sidebar Navigation */}
                        <Grid item xs={12} md={3}>
                            <Box sx={{ position: 'sticky', top: 80 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    API Sections
                                </Typography>
                                <Button
                                    fullWidth
                                    variant={activeTab === 'authentication' ? 'contained' : 'outlined'}
                                    sx={{ mb: 2 }}
                                    onClick={() => setActiveTab('authentication')}
                                >
                                    Authentication
                                </Button>
                                {Object.keys(endpoints).map(section => (
                                    <Button
                                        key={section}
                                        fullWidth
                                        variant={activeTab === section ? 'contained' : 'outlined'}
                                        sx={{ mb: 2 }}
                                        onClick={() => setActiveTab(section)}
                                    >
                                        {section}
                                    </Button>
                                ))}
                            </Box>
                        </Grid>

                        {/* Documentation Content */}
                        <Grid item xs={12} md={9}>
                            {activeTab === 'authentication' ? (
                                <Box>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
                                        Authentication
                                    </Typography>

                                    <Card sx={{ mb: 4 }}>
                                        <CardContent>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                                API Keys
                                            </Typography>
                                            <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
                                                All API requests must include your API key in the headers:
                                            </Typography>
                                            <Paper sx={{ p: 2, backgroundColor: 'gray', color: 'secondary.light', fontFamily: 'monospace' }}>
                                                Authorization: Bearer YOUR_API_KEY
                                            </Paper>
                                        </CardContent>
                                    </Card>

                                    <Card sx={{ mb: 4 }}>
                                        <CardContent>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                                Rate Limits
                                            </Typography>
                                            <ul>
                                                <li>Free tier: 1000 requests/day</li>
                                                <li>Growth plan: 10,000 requests/day</li>
                                                <li>Enterprise plan: Unlimited requests</li>
                                            </ul>
                                        </CardContent>
                                    </Card>

                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                                Base URL
                                            </Typography>
                                            <Paper sx={{ p: 2, backgroundColor: 'gray.50', fontFamily: 'monospace' }}>
                                                https://api.virtacc.com/v1
                                            </Paper>
                                        </CardContent>
                                    </Card>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
                                        {activeTab} Endpoints
                                    </Typography>
                                    {endpoints[activeTab].map((endpoint, index) => (
                                        <Card key={index} sx={{ mb: 4 }}>
                                            <CardContent>
                                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                                    <Paper sx={{ backgroundColor: 'green', p: 1, borderRadius: 2, mr: 2 }}>
                                                        <Typography variant="body2" sx={{ color: 'white', fontFamily: 'monospace' }}>
                                                            {endpoint.method}
                                                        </Typography>
                                                    </Paper>
                                                    <Typography variant="body2" sx={{ fontFamily: 'monospace', color: 'text.primary' }}>
                                                        {endpoint.endpoint}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
                                                    {endpoint.description}
                                                </Typography>

                                                <Card sx={{ backgroundColor: 'secondary.main', mb: 2 }}>
                                                    <CardContent>
                                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                                                            Parameters
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ fontFamily: 'monospace', color: 'text.primary' }}>
                                                            {endpoint.parameters}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>

                                                <Card sx={{ backgroundColor: 'secondary.dark' }}>
                                                    <CardContent>
                                                        <Typography variant="h6" sx={{ color: 'secondary.contrastText', fontWeight: 'bold', mb: 1 }}>
                                                            Example Response
                                                        </Typography>
                                                        <pre style={{ fontFamily: 'monospace', overflowX: 'auto' }}>
                                                            {endpoint.response}
                                                        </pre>
                                                    </CardContent>
                                                </Card>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Container>

                {/* SDK Section */}
                <Box sx={{ backgroundColor: 'secondary.main', py: 8 }}>
                    <Container>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }} align="center">
                            Official SDKs
                        </Typography>
                        <Grid container spacing={4} justifyContent="center">
                            {['Node.js', 'Python', 'PHP'].map((sdk, index) => (
                                <Grid item xs={12} md={3} key={index}>
                                    <Card sx={{ textAlign: 'center', p: 4 }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                            {sdk}
                                        </Typography>
                                        <Paper sx={{ p: 2, backgroundColor: 'secondary.dark', color: 'text.primary', fontFamily: 'monospace', mb: 2 }}>
                                            {sdk === 'Node.js' ? 'npm install virtacc-node' : sdk === 'Python' ? 'pip install virtacc' : 'composer require virtacc/virtacc-php'}
                                        </Paper>
                                        <Button variant="outlined" href='#' color='primary'>
                                            View Documentation →
                                        </Button>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Box>

                {/* Support Section */}
                <Box sx={{ py: 8 }}>
                    <Container>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }} align="center">
                            Need Help?
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }} align="center">
                            Our developer support team is always ready to help you integrate our API
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
                            <Button variant="outlined" href="#" color='primary'>
                                Join Developer Community
                            </Button>
                            <Divider orientation="vertical" flexItem />
                            <Button variant="outlined" href="#" color='primary'>
                                Contact Support
                            </Button>
                        </Box>
                    </Container>
                </Box>

                {/* Footer section */}
                <Footer />

            </Box>

        </PageContainer>
    );
};

export default APIDocumentation;
