import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useNavigate } from "react-router-dom";
import { Helper } from '../../utils/Helper';
import { toast } from 'react-toastify';
import Layout from '../../components/Auth/Layout';
import { Button, Card, Checkbox, FormControlLabel, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from '../../paths';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { userDataPayload } from '../../interfaces/UserPayload';
import AuthService from './api/Auth.api';
import { UsersType } from '../../interfaces/Users';

const Register: React.FC = () => {

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<UsersType>({
    first_name: '', last_name: '', username: '', role: '', email: '', password: '', mobile_number: '', organization: '', is_active: true
  });

  // Handle checkbox change
  // const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormData({
  //     ...formData,
  //     termsAccepted: event.target.checked,
  //   });
  // };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignUp = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const signUpFlag = await AuthService.signUp(
        formData.username,
        formData.email,
        formData.password,
        formData.organization,
        formData.role
      );
      if (signUpFlag) {
        const userData: userDataPayload | null = Helper.getCurrentUser();
        if (userData) {
          const destination = userData.role === '0' ? '/organizations' : '/dashboard';
          navigate(destination, { replace: true });
          toast.success(`Welcome ${userData.email}`);
        }
        setLoading(false);
      }
      else {
        toast.error(`Signup Failed!`);
        setLoading(false);
      }
    } catch (error) {
      toast.error('User Sign-up failed. Please try again');
      setLoading(false);
    }
  };

  const renderForm = (
    <form onSubmit={handleSignUp}>
      <Stack spacing={3}>

        <TextField
          name="username"
          label="Username"
          value={formData.username}
          onChange={onChange}
          required
        />

        <TextField
          name="email"
          type="email"
          label="Email address"
          value={formData.email}
          onChange={onChange}
          required
        />

        <TextField
          name="organization"
          label="Organization name"
          value={formData.organization}
          onChange={onChange}
          required
        />

        <TextField
          name="role"
          label="Role"
          value={formData.role}
          onChange={onChange}
          required
        />

        <TextField
          name="password"
          label="Password"
          value={formData.password}
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />
        <Button
          fullWidth
          type='submit'
          variant='contained'
          disabled={loading}
        >
          Sign Up
        </Button>
      </Stack>
      {/* 
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.termsAccepted}
                onChange={handleCheckboxChange}
              // {...formData}
              />
            }
            label={
              <>
                I have read the <Link component={RouterLink} to={paths.legals.termsOfService}>terms and conditions</Link>
              </>
            }
            required
          />
      */}
    </form>
  );

  return (
    <Layout>
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4">Sign up</Typography>
          <Typography color="text.secondary" variant="body2" sx={{ mt: 2, mb: 5 }}>
            Already have an account?{' '}
            <Link component={RouterLink} to={paths.auth.signIn} underline="hover" variant="subtitle2" sx={{ ml: 0.5 }}>
              Sign in
            </Link>
          </Typography>
          {renderForm}
        </Card>
      </Stack>
    </Layout>
  );

};

export default Register;
