import * as React from 'react';
// import type { Metadata } from 'next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Layout from '../../Dashboard/Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';
import AllOrders from '../../../components/Dashboard/Orders/AllOrders';
import OrdersService from './api/Orders.api';
import { OrdersType } from '../../../interfaces/Orders';
import { toast } from 'react-toastify';

const OrdersDashboard: React.FC = () => {

  const [ordersData, setOrdersData] = React.useState<OrdersType[]>([]);

  React.useEffect(() => {
    fetchOrdersFromDB();
  }, [])

  const fetchOrdersFromDB = async () => {
    let orders = await OrdersService.getAllOrders();
    if (orders.data) setOrdersData(orders.data);
    else toast.warn(orders.message);
  }

  return (
    <PageContainer title={'Orders | Dashboard | ' + config.site.name} description='This is Orders page of Virtacc'>
      <Layout>
        <Stack spacing={3}>
          <Typography variant="h4">Orders</Typography>
          <AllOrders orders={ordersData} sx={{ height: '100%' }} />
        </Stack>
      </Layout>
    </PageContainer>
  );
}

export default OrdersDashboard;
