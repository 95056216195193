import React, { useState } from 'react';
import {
    Search,
    Book,
    Video,
    MessageCircle,
    Rocket,
    Settings,
    DollarSign,
    ChevronRight
} from 'lucide-react';
import {
    Box,
    Container,
    TextField,
    Grid,
    Typography,
    Paper,
    Button,
    Card,
} from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';
import { config } from '../../config';
import { paths } from '../../paths';

// Types for Category and Article
interface Category {
    title: string;
    icon: React.ReactNode;
    articles: string[];
}

interface Article {
    title: string;
    views: string;
    category: string;
}

const HelpCenter: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const contactPage = () => { return window.location.href = paths.main.contactUs }
    const categories: Category[] = [
        {
            title: "Getting Started",
            icon: <Rocket />,
            articles: [
                "Quick Start Guide",
                "Setting Up Your Account",
                "Connecting Your Amazon Store",
                "Integrating Shopify"
            ]
        },
        {
            title: "Account & Billing",
            icon: <DollarSign />,
            articles: [
                "Managing Subscription",
                "Payment Methods",
                "Billing FAQ",
                "Upgrading Your Plan"
            ]
        },
        {
            title: "Platform Features",
            icon: <Settings />,
            articles: [
                "Dashboard Overview",
                "Financial Reports",
                "Profit Analytics",
                "Inventory Tracking"
            ]
        }
    ];

    const popularArticles: Article[] = [
        {
            title: "How to reconcile Amazon fees",
            views: "2.5k views",
            category: "Accounting"
        },
        {
            title: "Setting up multi-channel tracking",
            views: "1.8k views",
            category: "Integration"
        },
        {
            title: "Understanding profit analytics",
            views: "1.5k views",
            category: "Analytics"
        }
    ];

    return (
        <PageContainer title='Help Center | Virtacc' description='This is Help Center page of Virtacc'>

            <Box sx={{ minHeight: '100vh', backgroundColor: 'primary.contrastText' }}>

                {/* Navbar Section */}
                <Navbar backgroundColor='primary.main' />

                {/* Hero Section with Search */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 10 }}>
                    <Container>
                        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
                            How can we help?
                        </Typography>
                        <Box sx={{ maxWidth: 500, mx: 'auto' }}>
                            {/* <Box sx={{ position: 'relative' }}> */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Search for help articles..."
                                value={searchQuery}
                                color='primary'
                                sx={{
                                    backgroundColor: 'primary.contrastText',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                    },
                                    '& .MuiInputBase-input': {
                                        paddingLeft: 3,
                                        paddingRight: 3
                                    }
                                }}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <Search style={{ position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)', color: 'primary.main' }} />
                                    )
                                }}
                            />
                            {/* </Box> */}
                        </Box>
                    </Container>
                </Box>

                {/* Quick Links */}
                <Box sx={{ py: 8, backgroundColor: 'secondary.dark' }}>
                    <Container>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ textAlign: 'center', p: 4 }}>
                                    <Book style={{ fontSize: 40, color: 'primary.main', marginBottom: 2 }} />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Documentation</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Detailed guides and references</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ textAlign: 'center', p: 4 }}>
                                    <Video style={{ fontSize: 40, color: 'primary.main', marginBottom: 2 }} />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Video Tutorials</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Step-by-step video guides</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ textAlign: 'center', p: 4 }}>
                                    <MessageCircle style={{ fontSize: 40, color: 'primary.main', marginBottom: 2 }} />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Community</Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Connect with other users</Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                {/* Categories */}
                <Container maxWidth="lg" sx={{ py: 8 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>Browse by Category</Typography>
                    <Grid container spacing={4}>
                        {categories.map((category) => (
                            <Grid item xs={12} sm={4} key={category.title}>
                                <Card sx={{ p: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Box sx={{ color: 'primary.main', mb: 3 }}>{category.icon}</Box>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{category.title}</Typography>
                                    <ul>
                                        {category.articles.map((article) => (
                                            <li key={article} style={{ display: 'flex', alignItems: 'center' }}>
                                                <ChevronRight style={{ color: 'secondary.dark', marginRight: 1 }} />
                                                <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>
                                                    {article}
                                                </Typography>
                                            </li>
                                        ))}
                                    </ul>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>

                {/* Popular Articles */}
                <Box sx={{ backgroundColor: 'secondary.main', py: 8 }}>
                    <Container maxWidth="lg">
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>Popular Articles</Typography>
                        <Grid container spacing={4}>
                            {popularArticles.map((article) => (
                                <Grid item xs={12} sm={6} md={4} key={article.title}>
                                    <Card sx={{ p: 4, cursor: 'pointer', boxShadow: 3, '&:hover': { boxShadow: 6 } }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                            {article.title}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="body2" sx={{ color: 'text.secondary', mr: 3 }}>
                                                {article.views}
                                            </Typography>
                                            <Paper sx={{ px: 2, py: 1, backgroundColor: 'gray.100' }}>
                                                {article.category}
                                            </Paper>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Box>

                {/* Contact Support */}
                <Container maxWidth="lg" sx={{ py: 8 }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>Can't find what you're looking for?</Typography>
                        <Typography sx={{ color: 'text.secondary', mb: 4 }}>Our support team is here to help you</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
                            <Button onClick={contactPage} variant="contained" color="primary" sx={{ px: 4, py: 2 }}>
                                Contact Support
                            </Button>
                            <Button variant="outlined" color="primary" sx={{ px: 4, py: 2 }}>
                                Schedule a Demo
                            </Button>
                        </Box>
                    </Box>
                </Container>

                {/* Quick Support Options */}
                <Box sx={{ backgroundColor: 'secondary.main', py: 8 }}>
                    <Container maxWidth="lg">
                        <Grid container spacing={4} sx={{ display: 'flex', alignContent: 'center' }}>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Email Support:</Typography>
                                    <a href={`mailto:${config.support.email}`} style={{ color: 'primary.main', textDecoration: 'none' }}>{config.support.email}</a>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Phone Support:</Typography>
                                    <Typography variant="body1">{config.support.number}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Hours:</Typography>
                                    <Typography variant="body1">{config.support.time}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                {/* Footer Section */}
                <Footer />

            </Box>

        </PageContainer>
    );
};

export default HelpCenter;
