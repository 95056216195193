import type { PaletteRange } from '@mui/material/styles/createPalette';

export const california = { // used as warning in both light and dark modes
  50: '#fffaea',
  100: '#fff3c6',
  200: '#ffe587',
  300: '#ffd049',
  400: '#ffbb1f',
  500: '#fb9c0c',
  600: '#de7101',
  700: '#b84d05',
  800: '#953b0b',
  900: '#7b310c',
  950: '#471701',
} satisfies PaletteRange;

export const kepple = { // used as success in both light and dark modes
  50: '#f0fdfa',
  100: '#ccfbef',
  200: '#9af5e1',
  300: '#5fe9ce',
  400: '#2ed3b8',
  500: '#15b79f',
  600: '#0e9382',
  700: '#107569',
  800: '#115e56',
  900: '#134e48',
  950: '#042f2c',
} satisfies PaletteRange;

// export const neonBlue = { // commented primary in both light and dark modes // original theme
//   50: '#ecf0ff',
//   100: '#dde3ff',
//   200: '#c2cbff',
//   300: '#9ca7ff',
//   400: '#7578ff',
//   500: '#635bff',
//   600: '#4e36f5',
//   700: '#432ad8',
//   800: '#3725ae',
//   900: '#302689',
//   950: '#1e1650',
// } satisfies PaletteRange;

export const vibrantOrange = { // commented primary in both light and dark modes // last used orange
  50: '#FFEFE5',
  100: '#FFD8BF',
  200: '#FFBA99',
  300: '#FF9D73',
  400: '#FF7F4D',
  500: '#F26723',
  600: '#D8551F',
  700: '#BF441B',
  800: '#A63317',
  900: '#8C2213',
  950: '#66110A',
} satisfies PaletteRange;

export const deepBlue = { // used as neutral in both light and dark modes
  50: '#E5ECF4',
  100: '#BFCEE1',
  200: '#95ABCB',
  300: '#6B88B5',
  400: '#4B6DA4',
  500: '#173969',
  600: '#153361',
  700: '#122C56',
  800: '#0F244B',
  900: '#0B193A',
  950: '#050C1E',
} satisfies PaletteRange;

// export const nevada = { // commented secondary in both light and dark modes // original theme
//   50: '#fbfcfe',
//   100: '#f0f4f8',
//   200: '#dde7ee',
//   300: '#cdd7e1',
//   400: '#9fa6ad',
//   500: '#636b74',
//   600: '#555e68',
//   700: '#32383e',
//   800: '#202427',
//   900: '#121517',
//   950: '#090a0b',
// } satisfies PaletteRange;

export const redOrange = { // used as error in both light and dark modes
  50: '#fef3f2',
  100: '#fee4e2',
  200: '#ffcdc9',
  300: '#fdaaa4',
  400: '#f97970',
  500: '#f04438',
  600: '#de3024',
  700: '#bb241a',
  800: '#9a221a',
  900: '#80231c',
  950: '#460d09',
} satisfies PaletteRange;

export const shakespeare = { // used as info in both light and dark modes
  50: '#ecfdff',
  100: '#cff7fe',
  200: '#a4eefd',
  300: '#66e0fa',
  400: '#10bee8',
  500: '#04aad6',
  600: '#0787b3',
  700: '#0d6d91',
  800: '#145876',
  900: '#154964',
  950: '#082f44',
} satisfies PaletteRange;

export const stormGrey = { // nowhere used
  50: '#f9fafb',
  100: '#f1f1f4',
  200: '#dcdfe4',
  300: '#b3b9c6',
  400: '#8a94a6',
  500: '#667085',
  600: '#565e73',
  700: '#434a60',
  800: '#313749',
  900: '#212636',
  950: '#121621',
} satisfies PaletteRange;

export const logoBlue = { // was used as primary in both light and dark modes
  50: '#B2E0FF',
  100: '#80CFFF',
  200: '#4DAFFF',
  300: '#269CFF',
  400: '#1C8FFF',
  500: '#00B2FF',
  600: '#0091E5',
  700: '#0075C0',
  800: '#005A9D',
  900: '#00407B',
  950: '#002B58',
} satisfies PaletteRange;

export const logoGreen = { // was used as secondary in both light and dark modes
  50: "#B2FFD1",
  100: "#80F9B6",
  200: "#4DFB9C",
  300: "#26FF7B",
  400: "#1CFF6E",
  500: "#00F937",
  600: "#00D72D",
  700: "#00B623",
  800: "#009B1A",
  900: "#008610",
  950: "#006E07"
} satisfies PaletteRange;

export const newColPrimary = { // #1e3a8a from new homepage // used as primary
  200: "#2c54c9",
  300: "#274cb4",
  400: "#23439f",
  500: "#1e3a8a",
  600: "#193175",
  700: "#152860",
  800: "#10204b",
}

export const offWhite = { // #f9fafb from new homepage // used as secondary
  200: '#ffffff',
  300: '#ffffff',
  400: '#ffffff',
  500: '#f9fafb',
  600: '#eaedf1',
  700: '#dae1e7',
  800: '#cbd4dc',
}