import React from 'react'
import Layout from '../Layout'
import { Button, IconButton, Modal, Stack, Typography } from '@mui/material'
import PageContainer from '../../../components/PageContainer/PageContainer'
import { config } from '../../../config'
import AddIcon from '@mui/icons-material/Add';
import UsersTable from '../../../components/Dashboard/Settings/Users/UsersTable'
import UserService from './api/Users.api'
import { UsersType } from '../../../interfaces/Users'
import { toast } from 'react-toastify'
import { Close } from '@mui/icons-material'
import AddUserForm from '../../../components/Dashboard/Settings/Users/AddUserForm'
import { Helper } from '../../../utils/Helper'

const Users: React.FC = () => {

    const [users, setUsers] = React.useState<UsersType[]>([]);
    const [modelOpen, setModelOpen] = React.useState(false);

    React.useEffect(() => {
        getUsers();
    }, []);

    const currentUser = Helper.getCurrentUser();

    const getUsers = async () => {
        const users = await UserService.getUsers();
        if (users.data) setUsers(users.data);
        else toast.warn(users.message);
    }

    const handleModalOpen = () => setModelOpen(true);
    const handleModalClose = () => setModelOpen(false);
    const handleFormSuccess = () => {
        handleModalClose(); // Close the modal
        getUsers();
    };
    const styleModal = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',  // Ensure the modal takes 90% width on smaller screens
        maxWidth: 600, // Set a maximum width for large screens
        bgcolor: 'background.paper',
        border: '1px solid var(--mui-palette-primary-main)',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4,
        maxHeight: '80vh', // Ensure the modal doesn't exceed the screen height (80% of viewport height)
        overflowY: 'auto', // Allow vertical scrolling when content overflows
        '::-webkit-scrollbar': {
            width: '0px',
        },
    };
    const renderModal = (
        <Modal
            open={modelOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-add-organization"
            aria-describedby="modal-add-organization-description"
        >
            <Stack sx={styleModal}>
                <Stack direction="row" spacing={3}>
                    <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
                        <Typography sx={{ mb: 3 }} variant="h5">Add User</Typography>
                    </Stack>
                    <IconButton
                        aria-label="close"
                        onClick={handleModalClose}
                        sx={{ position: 'absolute', right: 20 }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <AddUserForm onSuccess={handleFormSuccess} />
            </Stack>
        </Modal>
    );

    return (
        <PageContainer title={'Users | Dashboard | ' + config.site.name} description='This is Users page of Virtacc'>
            <Layout>
                <Stack spacing={3}>
                    <Stack direction="row" spacing={3}>
                        <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
                            <Typography variant="h4">Users</Typography>
                        </Stack>
                        {
                            currentUser?.role === '1' || currentUser?.role === '0' ? (
                                <>
                                    <Button onClick={handleModalOpen} startIcon={<AddIcon />} variant="contained">
                                        Add
                                    </Button>
                                    {renderModal}
                                </>
                            ) : (<></>)
                        }

                    </Stack>
                    <UsersTable sx={{ height: '100%' }} users={users} currentUser={currentUser} />
                </Stack>
            </Layout>
        </PageContainer>
    );

}

export default Users