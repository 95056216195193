import React, { useState } from 'react'
import PageContainer from '../../../components/PageContainer/PageContainer';
import Layout from '../Layout';
import { Card, Stack, Typography } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import { config } from '../../../config';
import PieClickNoSnap from './PieChart';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { OrdersType } from '../../../interfaces/Orders';
import OrdersService from '../Orders/api/Orders.api';
import { toast } from 'react-toastify';
// import ScatterChart from './ScatterChart';

interface revenueExpense {
    label: string,
    value: number,
}

const Charts: React.FC = () => {
    const [ordersData, setOrdersData] = React.useState<OrdersType[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<OrdersType[]>([]);
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
        dayjs().subtract(1, 'month'),
        dayjs(),
    ]);
    const [chartData, setChartData] = React.useState<revenueExpense[]>([]);

    // Fetch orders only on component mount
    React.useEffect(() => {
        const fetchOrdersFromDB = async () => {
            let orders = await OrdersService.getAllOrders();
            if (orders.data) setOrdersData(orders.data);
            else toast.warn(orders.message);
        };
        fetchOrdersFromDB();
    }, []);

    // Filter orders whenever dateRange or ordersData changes
    React.useEffect(() => {
        if (ordersData.length > 0 && dateRange[0] && dateRange[1]) {
            const filtered = ordersData.filter(order => {
                const orderDate = dayjs(order.OrderDate);
                return orderDate.isAfter(dateRange[0]) && orderDate.isBefore(dateRange[1]);
            });
            setFilteredOrders(filtered);
        } else {
            setFilteredOrders(ordersData);
        }
    }, [dateRange, ordersData]);

    React.useEffect(() => {
        if (filteredOrders.length > 0) {
            let revenueData = 0;
            let expenseData = 0;

            filteredOrders.forEach((order) => {
                const estimatedPayout = Number(order.EstimatedPayout) || 0;
                const totalExpense = Number(order.TotalExpense) || 0;

                revenueData += estimatedPayout;
                expenseData += totalExpense;
            });

            setChartData([
                { label: 'expense', value: Math.abs(expenseData) },
                { label: 'revenue', value: Math.abs(revenueData) }
            ])
        } else {
            // Handle case when no orders match the filter
            setChartData([
                { label: 'expense', value: 0 },
                { label: 'revenue', value: 0 }
            ])
        }
    }, [filteredOrders]); // Add `filteredOrders` as a dependency here

    return (
        <PageContainer title={'Charts | Dashboard | ' + config.site.name} description='This is Charts page of Virtacc'>
            <Layout>
                <Stack spacing={3}>
                    <Typography variant="h4">Charts</Typography>
                    <Card sx={{ p: 2 }}>
                        <Grid lg={6} xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangePicker
                                    localeText={{ start: "From Date", end: "To Date" }}
                                    value={dateRange}
                                    onChange={(newValue: DateRange<dayjs.Dayjs>) => setDateRange(newValue)}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Card>
                    <Grid container spacing={3}>
                        <Grid>
                            {/* <PieChart data={data} onClickSection={handleSectionClick} /> */}
                            <PieClickNoSnap
                                data={chartData} sx={{ height: '100%' }}
                            />
                        </Grid>
                        <Grid>
                            {chartData.map((rev) => {
                                return (
                                    <>
                                        <p>Label: {rev.label}</p>
                                        <p>Value: {rev.value}</p>
                                    </>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        {/* <ScatterChart revenue={revenueX} expense={expenseX} /> */}
                    </Grid>
                </Stack>
            </Layout>
        </PageContainer>
    );
};

export default Charts