import React from 'react'
import Layout from '../Layout'
import { Typography } from '@mui/material'
import PageContainer from '../../../components/PageContainer/PageContainer'
import { config } from '../../../config'

const Billing: React.FC = () => {
  return (
    <PageContainer title={'Billing | Dashboard | ' + config.site.name} description='This is Billing page of Virtacc'>
      <Layout>
        <Typography variant="h4">Billing</Typography>
      </Layout>
    </PageContainer>
  )
}

export default Billing