import { CustomFetch } from "../../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const getAllOrders = async () => {
    const response = await CustomFetch(API_URL + '/orders', { method: 'GET' });
    return response.json();
}

// const getAllOrders = async () => {
//     const response = await CustomFetch(API_URL + '/amazon/orders', {method: 'GET'});
//     return response.json();
// }

function isValidOrderId(orderId: string) {
    // Regular expression for the pattern 'xxx-xxxxxxx-xxxxxxx', where x is alphanumeric
    const regex = /^[a-zA-Z0-9]{3}-[a-zA-Z0-9]{7}-[a-zA-Z0-9]{7}$/;
    return regex.test(orderId);
}

const getFilterOrderByOrderId = async (orderId: string) => {
    if (!isValidOrderId(orderId)) {
        return { data: null, error: '[Orders.api] improper format: orderId' }
    } else {
        try {
            console.log('THIS IS ORDERID ', orderId);
            const response = await CustomFetch(API_URL + '/orders/sp-api/' + orderId, { method: 'GET' });
            return { data: await response.json(), error: null }
        } catch (error) {
            return { data: null, error: error }
        }
    }
}

const OrdersService = {
    getAllOrders,
    // getFinancialEvent
    getFilterOrderByOrderId
}

export default OrdersService;