import React, { useState } from "react";
import { Card, CardContent, Typography, Grid, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';
import { ppcType } from "../../../../interfaces/PPC";
import dayjs from "dayjs";

interface KKPIMetricsProps {
    ppcData: ppcType[]; // Array of ppcType data to pass to the chart
}

const KKPIMetrics: React.FC<KKPIMetricsProps> = ({ ppcData = [] }) => {
    // Set default date and selected data
    const [selectedDate, setSelectedDate] = useState<string>(ppcData[0]?.date || '');
    const [metrics, setMetrics] = useState({
        totalSpends: 0,
        totalClicks: 0,
        totalImpressions: 0,
        CTR: 0,
        ACoS: 0
    });

    // Update the metrics when a new date is selected
    const handleDateChange = (event: SelectChangeEvent<string>) => {
        const date = event.target.value;
        setSelectedDate(date);

        // Find data for the selected date
        const selectedData = ppcData.find(item => item.date === date);
        if (selectedData) {
            // Calculate metrics based on selected date's data
            setMetrics({
                totalSpends: selectedData.spend,
                totalClicks: selectedData.clicks,
                totalImpressions: selectedData.impressions,
                CTR: selectedData.clickThroughRate, // Clicks / Impressions
                ACoS: selectedData.acosClicks14d // or other formula based on your needs
            });
        }
    };

    // Collect the list of available dates
    const availableDates = Array.from(new Set( Array.isArray(ppcData) ? ppcData.map(item => item.date) : [] ));

    return (
        <div>
            {/* Date selection dropdown */}
            <FormControl fullWidth margin="normal">
                <InputLabel>Choose Date</InputLabel>
                <Select
                    value={selectedDate}
                    onChange={handleDateChange}
                    label="Choose Date"
                >
                    {availableDates.map(date => (
                        <MenuItem key={date} value={date}>
                            {dayjs(date).format('DD MMM')}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Displaying KPI Metrics */}
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Total Spends</Typography>
                            <Typography variant="h4">${metrics.totalSpends}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Total Clicks</Typography>
                            <Typography variant="h4">{metrics.totalClicks}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Total Impressions</Typography>
                            <Typography variant="h4">{metrics.totalImpressions}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">CTR</Typography>
                            <Typography variant="h4">{metrics.CTR}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">ACoS</Typography>
                            <Typography variant="h4">{metrics.ACoS}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default KKPIMetrics;
