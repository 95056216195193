import {
    BarChart,
    DollarSign,
    PieChart,
    Settings,
    RefreshCcw,
    Activity,
    ShoppingCart,
    AlertCircle,
    Calculator,
    Clock
} from 'lucide-react';
import {
    Container,
    Grid,
    Typography,
    Box,
    Paper,
    Button,
    List,
    ListItem,
    ListItemIcon
} from '@mui/material';
import { styled } from '@mui/system';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';
import { paths } from '../../paths';

const IconWrapper = styled(Box)({
    width: '48px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f3f4f6',
    borderRadius: '50%',
});

const FeaturesPage = () => {
    const contactPage = () => { return window.location.href = paths.main.contactUs }
    return (
        <PageContainer title='Features | Virtacc' description='This is Features page of Virtacc'>

            {/* Navbar Section */}
            <Navbar backgroundColor='primary.main' />

            {/* Hero Section */}
            <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 12 }}>
                <Container>
                    <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
                        Powerful Features for E-commerce Success
                    </Typography>
                    <Typography variant="h6" align="center" sx={{ mt: 2, color: 'primary.contrastText' }}>
                        Everything you need to manage your e-commerce finances, track profitability, and scale your business.
                    </Typography>
                </Container>
            </Box>

            {/* Core Features Grid */}
            <Box sx={{ py: 10 }}>
                <Container maxWidth="lg">
                    <Typography variant="h4" component="h2" align="center" gutterBottom>
                        Core Features
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {/* Real-time Dashboard */}
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 3, alignContent: 'center' }}>
                                <IconWrapper>
                                    <BarChart style={{ color: 'primary.main' }} />
                                </IconWrapper>
                                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                                    Real-time Dashboard
                                </Typography>
                                <Typography variant="body2">
                                    Live financial metrics, sales data, and performance indicators at your fingertips.
                                </Typography>
                            </Paper>
                        </Grid>

                        {/* Profit Analytics */}
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
                                <IconWrapper>
                                    <DollarSign style={{ color: 'primary.main' }} />
                                </IconWrapper>
                                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                                    Profit Analytics
                                </Typography>
                                <Typography variant="body2">
                                    Detailed breakdown of profits, costs, and margins across all your products.
                                </Typography>
                            </Paper>
                        </Grid>

                        {/* Automated Reconciliation */}
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
                                <IconWrapper>
                                    <RefreshCcw style={{ color: 'primary.main' }} />
                                </IconWrapper>
                                <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
                                    Automated Reconciliation
                                </Typography>
                                <Typography variant="body2">
                                    Automatic matching and categorization of transactions and fees.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Detailed Feature Sections */}
            <Box sx={{ bgcolor: 'secondary.main', py: 10 }}>
                <Container maxWidth="lg">
                    {/* Financial Management */}
                    <Box sx={{ mb: 10 }}>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5" sx={{ mb: 2 }}>
                                    Financial Management
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <PieChart style={{ color: 'primary.main' }} />
                                        </ListItemIcon>
                                        <Typography variant="body2">Advanced expense tracking and categorization</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Calculator style={{ color: 'primary.main' }} />
                                        </ListItemIcon>
                                        <Typography variant="body2">Automated fee calculation and reconciliation</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Activity style={{ color: 'primary.main' }} />
                                        </ListItemIcon>
                                        <Typography variant="body2">Real-time profit and loss monitoring</Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img
                                    src="/assets/images/placeholder/500x300.svg"
                                    alt="Financial management interface 500x300"
                                    style={{ borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Platform Integration */}
                    <Box sx={{ mb: 10 }}>
                        <Grid container spacing={4} alignItems="center" direction="row-reverse">
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5" sx={{ mb: 2 }}>
                                    Seamless Platform Integration
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ShoppingCart style={{ color: 'primary.main' }} />
                                        </ListItemIcon>
                                        <Typography variant="body2">Direct integration with Amazon and Shopify</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Settings style={{ color: 'primary.main' }} />
                                        </ListItemIcon>
                                        <Typography variant="body2">Automated data synchronization</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Clock style={{ color: 'primary.main' }} />
                                        </ListItemIcon>
                                        <Typography variant="body2">Real-time updates and notifications</Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img
                                    src="/assets/images/placeholder/500x300.svg"
                                    alt="Platform integration diagram 500x300"
                                    style={{ borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            {/* Feature Comparison */}
            <Box sx={{ py: 10 }}>
                <Container maxWidth="lg">
                    <Typography variant="h4" component="h2" align="center" gutterBottom>
                        Why Choose Virtacc
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {/* Manual Accounting */}
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Manual Accounting
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AlertCircle />
                                        </ListItemIcon>
                                        <Typography variant="body2">Time-consuming data entry</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AlertCircle />
                                        </ListItemIcon>
                                        <Typography variant="body2">Prone to human error</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AlertCircle />
                                        </ListItemIcon>
                                        <Typography variant="body2">Delayed insights</Typography>
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>

                        {/* Virtacc */}
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 3, bgcolor: 'primary.main', color: 'primary.contrastText', borderRadius: 2, boxShadow: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Virtacc
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Settings />
                                        </ListItemIcon>
                                        <Typography variant="body2">Automated data processing</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Settings />
                                        </ListItemIcon>
                                        <Typography variant="body2">99.9% accuracy</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Settings />
                                        </ListItemIcon>
                                        <Typography variant="body2">Real-time insights</Typography>
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>

                        {/* Generic Software */}
                        <Grid item xs={12} md={4}>
                            <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Generic Software
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AlertCircle className="w-5 h-5" />
                                        </ListItemIcon>
                                        <Typography variant="body2">Not e-commerce specific</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AlertCircle className="w-5 h-5" />
                                        </ListItemIcon>
                                        <Typography variant="body2">Limited integrations</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AlertCircle className="w-5 h-5" />
                                        </ListItemIcon>
                                        <Typography variant="body2">Complex setup</Typography>
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* CTA Section */}
            <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', py: 10 }}>
                <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
                    <Typography variant="h4" component="h2" gutterBottom>
                        Ready to transform your e-commerce accounting?
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'primary.contrastText', mb: 4 }}>
                        Start your free trial today and experience the difference
                    </Typography>
                    <Button variant="contained" color="success" onClick={contactPage} size="large">
                        Get Started Free
                    </Button>
                </Container>
            </Box>

            {/* Footer Section */}
            <Footer />

        </PageContainer>
    );
};

export default FeaturesPage;
