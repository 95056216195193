import { UsersType } from "../../../../interfaces/Users";
import { CustomFetch } from "../../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const getUsers = async () => {
    const response = await CustomFetch(API_URL + '/users', { method: 'GET' });
    return response.json();
}

const updateUser = async (formdata: UsersType) => {
    return await CustomFetch(API_URL + '/users/' + formdata.id, {
        method: 'PUT',
        body: JSON.stringify(formdata)
    });
}

const createUser = async (formdata: UsersType) => {
    return await CustomFetch(API_URL + '/users/add', {
        method: 'POST',
        body: JSON.stringify(formdata)
    });
}

const UserService = {
    getUsers,
    updateUser,
    createUser
}

export default UserService;