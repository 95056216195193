import { Container, Box, Typography, Card, CardContent } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';
import { config } from '../../config';

const PrivacyPolicyPage = () => {
    return (
        <PageContainer title='Privacy Policy | Virtacc' description='This is Privacy Policy page of Virtacc'>

            <Box sx={{ minHeight: '100vh', backgroundColor: 'primary.contrastText' }}>

                {/* Navbar Section */}
                <Navbar backgroundColor='primary.main' />

                {/* Header Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 12 }}>
                    <Container>
                        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
                            Privacy Policy
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ mt: 2, color: 'primary.contrastText' }}>
                            Last Updated: December 20, 2024
                        </Typography>
                    </Container>
                </Box>

                {/* Content Section */}
                <Container sx={{ py: 16 }}>
                    <Box sx={{ mx: 'auto' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            1. Introduction
                        </Typography>
                        <Typography paragraph>
                            Virtacc Technologies Private Limited ("Virtacc", "we", "us", or "our") is committed to protecting your
                            privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you
                            use our service. This policy is in compliance with the Information Technology Act, 2000, and its associated rules.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            2. Information We Collect
                        </Typography>

                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            2.1 Personal Information
                        </Typography>
                        <ul>
                            <li> Name and contact information </li>
                            <li> Business information </li>
                            <li> E-commerce platform credentials </li>
                            <li> Payment information </li>
                            <li> Communication records </li>
                        </ul>

                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            2.2 Usage Data
                        </Typography>
                        <ul>
                            <li> Log data and analytics </li>
                            <li> Device information </li>
                            <li> Cookie data </li>
                        </ul>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            3. How We Use Your Information
                        </Typography>
                        <Typography paragraph>
                            We use your information for:
                        </Typography>
                        <ul>
                            <li> Providing and maintaining our Service </li>
                            <li> Processing your transactions </li>
                            <li> Analyzing and improving our Service </li>
                            <li> Communicating with you </li>
                            <li> Complying with legal obligations </li>
                        </ul>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            4. Data Storage and Security
                        </Typography>
                        <Typography paragraph>
                            We implement appropriate security measures in accordance with the Information Technology
                            (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.
                            Your data is stored securely in India with industry-standard encryption.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            5. Data Sharing and Disclosure
                        </Typography>
                        <Typography paragraph>
                            We may share your information with:
                        </Typography>
                        <ul>
                            <li> Service providers and business partners </li>
                            <li> Legal authorities when required by law </li>
                            <li> Third parties with your explicit consent </li>
                        </ul>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            6. Your Rights
                        </Typography>
                        <Typography paragraph>
                            You have the right to:
                        </Typography>
                        <ul>
                            <li> Access your personal information </li>
                            <li> Correct inaccurate data </li>
                            <li> Request deletion of your data </li>
                            <li> Withdraw consent </li>
                            <li> Export your data </li>
                        </ul>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            7. Cookies and Tracking
                        </Typography>
                        <Typography paragraph>
                            We use cookies and similar tracking technologies to track activity on our Service and hold certain
                            information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            8. Children's Privacy
                        </Typography>
                        <Typography paragraph>
                            Our Service does not address anyone under the age of 18. We do not knowingly collect personally
                            identifiable information from anyone under the age of 18.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            9. Changes to This Policy
                        </Typography>
                        <Typography paragraph>
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                            the new Privacy Policy on this page and updating the "Last Updated" date.
                        </Typography>
                    </Box>

                    {/* Contact Section */}
                    <Card sx={{ mt: 12, p: 3, backgroundColor: 'secondary.main' }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Contact Our Data Protection Officer
                            </Typography>
                            <Typography paragraph>
                                For any questions or concerns about your privacy, please contact our Data Protection Officer at:
                            </Typography>
                            <Typography>Email: <a href={`mailto:${config.privacyMail}`} style={{ textDecoration: 'none' }}>{config.privacyMail}</a></Typography>
                            <Typography>Address: {config.address.full}</Typography>
                            <Typography sx={{ mt: 2 }}>
                                Grievance Officer: {config.grievanvceOfficer.name}
                                <br />
                                Email: <a href={`mailto:${config.grievanvceOfficer.email}`} style={{ textDecoration: 'none' }}>{config.grievanvceOfficer.email}</a>
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>

                {/* Footer Section */}
                <Footer />

            </Box>

        </PageContainer>
    );
};

export default PrivacyPolicyPage;
