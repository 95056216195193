import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Layout from '../Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';
import AccountInfo from '../../../components/Dashboard/Settings/Account/AccountInfo';
import AccountDetailsForm from '../../../components/Dashboard/Settings/Account/AccountDetailsForm';

const Account: React.FC = () => {
  return (
    <PageContainer title={'Account | Dashboard | ' + config.site.name} description='This is Account page of Virtacc'>
      <Layout>
        <Stack spacing={3}>
          <Typography variant="h4">Account</Typography>
          <Grid container spacing={3}>
            <Grid lg={4} md={6} xs={12}>
              <AccountInfo />
            </Grid>
            <Grid lg={8} md={6} xs={12}>
              <AccountDetailsForm />
            </Grid>
          </Grid>
        </Stack>
      </Layout>
    </PageContainer>
  );
}

export default Account;
