import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Button,
  Collapse,
  Box,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { paths } from '../paths';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, showDetails: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  handleReload = () => {
    window.location.reload();
  };

  handleback = () => {
    window.location.href = paths.dashboard.overview;
  }

  toggleDetails = () => {
    this.setState((prevState: any) => ({ showDetails: !prevState.showDetails }));
  };

  render() {
    if (this.state.hasError) {
      return (
        <Container maxWidth="sm" style={{ marginTop: '50px', textAlign: 'center' }}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h4" color="error" gutterBottom>
              Something went wrong.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please try reloading the page.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={this.handleReload}
              style={{ marginBottom: '20px' }}
            >
              Reload
            </Button>
            <Box>
              <Typography variant="body1" gutterBottom>
                Or go back to Dashboard.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={this.handleback}
                style={{ marginBottom: '20px' }}
              >
                Dashboard
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                endIcon={this.state.showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={this.toggleDetails}
              >
                {this.state.showDetails ? 'Hide Details' : 'Show Details'}
              </Button>
              <Collapse in={this.state.showDetails}>
                <Box mt={2} p={2} bgcolor="grey.100">
                  <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                    {this.state.error && this.state.error.toString()}
                  </Typography>
                </Box>
              </Collapse>
            </Box>
          </Paper>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;