import { Button, Checkbox, FormControlLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { ChangeEvent, FC, FormEvent, useState, useEffect } from "react";
import { toast } from "react-toastify";
import organizationService from "../../../templates/Organizations/api/Organization.api";
import { SubscriptionsType } from "../../../interfaces/Subscriptions";
import subscriptionService from "../../../templates/Organizations/api/Subscriptions.api";
import { OrganizationsData } from "../../../interfaces/Organization";

interface UpdateSubscriptionFormProps {
    onSuccess: () => void;
    subscription: SubscriptionsType | null;
}

const UpdateSubscriptionForm: FC<UpdateSubscriptionFormProps> = ({ onSuccess, subscription }) => {
    // Set initial form data when subscription changes
    const [formData, setFormData] = useState<SubscriptionsType | null>(subscription);
    const [loading, setLoading] = useState<boolean>(false);
    const [organizationData, setOrganizationData] = useState<OrganizationsData[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<string>('');

    useEffect(() => {
        if (subscription) {
            delete subscription.subscription_key;
            delete subscription.is_deleted;
            delete subscription.created_by;
            delete subscription.created_at;
            delete subscription.updated_at;
            delete subscription.updated_by;
            subscription.subscription_type = 'basic'
            setFormData(subscription);
            setSelectedOrganization(subscription.organization); // Set initial organization
        }
    }, [subscription]);

    const getOrganizations = async () => {
        try {
            const response = await organizationService.getAllOrganizations();
            if (response.data) setOrganizationData(response.data);
        } catch (error) {
            toast.error('Failed to fetch organizations');
        }
    };

    useEffect(() => {
        getOrganizations(); // Fetch organizations when component mounts
    }, []);

    const availableOrganizations = Array.from(new Set(organizationData.map(item => item.organization)));

    // Handle organization change
    const handleOrganizationChange = (event: SelectChangeEvent<string>) => {
        const organization = event.target.value;
        setSelectedOrganization(organization);
        setFormData(prevData => ({
            ...prevData!,
            organization, // Update organization in formData
        }));
    };

    // Handle input field change
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData!,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    // Handle form submission (update subscription)
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!formData) return;

        setLoading(true);
        try {
            // Make the API call to update the subscription
            const response = await subscriptionService.updateSubscription(formData);
            if (response.ok) {
                toast.success('Subscription updated successfully!');
                onSuccess();
                setFormData({ organization: '', no_of_users: 0, starts_at: '', expire_at: '', is_active: true, payment_method: '' });
            } else {
                toast.error('Failed to update subscription!');
            }
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Stack sx={{ my: 3 }}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {/* Organization Selector */}
                    <Select
                        labelId="org"
                        value={selectedOrganization}
                        onChange={handleOrganizationChange}
                        label="Organization"
                        required
                    >
                        {availableOrganizations.map(org => (
                            <MenuItem key={org} value={org}>
                                {org}
                            </MenuItem>
                        ))}
                    </Select>

                    {/* Users Field */}
                    <TextField
                        name="no_of_users"
                        type="number"
                        label="Users"
                        value={formData?.no_of_users || ''}
                        onChange={onChange}
                        required
                    />

                    {/* Start Date Field */}
                    <TextField
                        name="starts_at"
                        type="date"
                        label="Starts at"
                        value={formData?.starts_at || ''}
                        onChange={onChange}
                        required
                        InputLabelProps={{
                            shrink: true, // Ensure the label doesn't overlap
                        }}
                    />

                    {/* Expiry Date Field */}
                    <TextField
                        name="expire_at"
                        type="date"
                        label="Expires at"
                        value={formData?.expire_at || ''}
                        onChange={onChange}
                        required
                        InputLabelProps={{
                            shrink: true, // Ensure the label doesn't overlap
                        }}
                    />

                    {/* Active Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData?.is_active}
                                onChange={onChange}
                                name="is_active"
                            />
                        }
                        label="Active"
                    />

                    {/* Active Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData?.is_deleted}
                                onChange={onChange}
                                name="is_deleted"
                            />
                        }
                        label="Delete"
                    />

                    {/* Submit Button */}
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save'}
                    </Button>
                </Stack>
            </form>
        </Stack>
    );
};

export default UpdateSubscriptionForm;
