import { contactForm, newsletter } from "../../../interfaces/SendMail";
import { CustomFetch } from "../../../utils/Fetch/Fetch";
const API_URL = process.env.REACT_APP_API_URL;

const sendMailContactForm = async (formData: contactForm) => {
    const response = await CustomFetch(API_URL + '/mailservice/contact-form', {
        method: 'POST',
        body: JSON.stringify(formData)
    });
    return await response.json();
}

const sendMailNewsletter = async (formData: newsletter) => {
    const response = await CustomFetch(API_URL + '/mailservice/newsletter', {
        method: 'POST',
        body: JSON.stringify(formData)
    });
    return response.json();
}

const mailService = {
    sendMailContactForm,
    sendMailNewsletter
}

export default mailService;