import { Box, Container, Typography } from "@mui/material"
import Footer from "./Footer"
import Navbar from "./Navbar";
import PageContainer from "../../components/PageContainer/PageContainer";

const ECommerceGuidePage: React.FC = () => {
    
    return (
        <PageContainer title='E-Commerce | Guides | Virtacc' description='This is E-Commerce Guide page of Virtacc'>

            <div style={{ minHeight: '100vh', backgroundColor: 'secondary.main' }}>
                
                {/* Navbar Section */}
                <Navbar backgroundColor="primary.main" />

                {/* Hero Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 12 }}>
                    <Container>
                        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
                            e-Commerce Guides
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ color: 'primary.contrastText', maxWidth: 900, mx: 'auto' }}>
                            Insights and guides for e-commerce success
                        </Typography>
                    </Container>
                </Box>
                
                {/* Footer Section */}
                <Footer />

            </div>

        </PageContainer>
    );

}

export default ECommerceGuidePage;
