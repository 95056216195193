import { Check, PhoneCall, Zap } from 'lucide-react';
import { Button, Card, CardContent, CardActions, Typography, Grid, Box, Container, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';

const PricingPage = () => {
    return (
        <PageContainer title='Pricing | Virtacc' description='This is Pricing page of Virtacc'>

            {/* Navbar Section */}
            <Navbar backgroundColor='primary.main' />

            {/* Hero Section */}
            <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 12 }}>
                <Container>
                    <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
                        Simple, Transparent Pricing
                    </Typography>
                    <Typography variant="h6" align="center" sx={{ mt: 2, color: 'primary.contrastText' }}>
                        Choose the perfect plan for your e-commerce business
                    </Typography>
                </Container>
            </Box>

            {/* Pricing Cards */}
            <Box sx={{ padding: '80px 0', maxWidth: '1200px', margin: '0 auto', backgroundColor: 'secondary.light' }}>
                <Container>
                    <Grid container spacing={4}>
                        {/* Growth Plan */}
                        <Grid item xs={12} md={6}>
                            <Card sx={{ border: '1px solid', borderColor: 'secondary.dark', borderRadius: '8px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)' }}>
                                <CardContent>
                                    <Typography variant="h5" component="h2" style={{ marginBottom: '16px', fontWeight: 'bold' }}>
                                        Growth
                                    </Typography>
                                    <Typography variant="body1" style={{ color: '#757575', marginBottom: '16px' }}>
                                        Perfect for growing e-commerce businesses
                                    </Typography>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                                            ₹999
                                        </Typography>
                                        <Typography variant="body1" style={{ color: '#757575', marginLeft: '8px' }}>
                                            /month
                                        </Typography>
                                    </div>
                                    <div style={{ marginTop: '16px' }}>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Check className="icon" style={{ width: '20px', height: '20px', color: '#4caf50', marginRight: '8px' }} />
                                                Up to 300 orders/month
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Check className="icon" style={{ width: '20px', height: '20px', color: '#4caf50', marginRight: '8px' }} />
                                                24/7 Email Support
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Check className="icon" style={{ width: '20px', height: '20px', color: '#4caf50', marginRight: '8px' }} />
                                                Real-time Dashboard
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Check className="icon" style={{ width: '20px', height: '20px', color: '#4caf50', marginRight: '8px' }} />
                                                Basic Analytics
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Check className="icon" style={{ width: '20px', height: '20px', color: '#4caf50', marginRight: '8px' }} />
                                                Amazon & Shopify Integration
                                            </li>
                                        </ul>
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <Button fullWidth variant="contained" color="primary">
                                        Get Started
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        {/* Enterprise Plan */}
                        <Grid item xs={12} md={6}>
                            <Card style={{ border: '2px solid', borderColor: 'primary.main', borderRadius: '8px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', position: 'relative' }}>
                                <Box sx={{ position: 'absolute', top: '16px', right: '16px', backgroundColor: 'primary.main', color: 'primary.contrastText', padding: '4px 8px', borderRadius: '8px', fontWeight: 'bold' }}>
                                    MOST POPULAR
                                </Box>
                                <CardContent>
                                    <Typography variant="h5" component="h2" style={{ marginBottom: '16px', fontWeight: 'bold' }}>
                                        Enterprise
                                    </Typography>
                                    <Typography variant="body1" style={{ color: '#757575', marginBottom: '16px' }}>
                                        For established e-commerce businesses
                                    </Typography>
                                    <Box style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                                            ₹2,499
                                        </Typography>
                                        <Typography variant="body1" style={{ color: '#757575', marginLeft: '8px' }}>
                                            /month
                                        </Typography>
                                    </Box>
                                    <Box style={{ marginTop: '16px' }}>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Zap className="icon" style={{ width: '20px', height: '20px', color: '#3f51b5', marginRight: '8px' }} />
                                                Unlimited Orders
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <PhoneCall className="icon" style={{ width: '20px', height: '20px', color: '#3f51b5', marginRight: '8px' }} />
                                                24/7 Priority Phone Support
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Check className="icon" style={{ width: '20px', height: '20px', color: '#4caf50', marginRight: '8px' }} />
                                                Advanced Analytics Dashboard
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Check className="icon" style={{ width: '20px', height: '20px', color: '#4caf50', marginRight: '8px' }} />
                                                Custom Report Builder
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Check className="icon" style={{ width: '20px', height: '20px', color: '#4caf50', marginRight: '8px' }} />
                                                Priority API Access
                                            </li>
                                            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Check className="icon" style={{ width: '20px', height: '20px', color: '#4caf50', marginRight: '8px' }} />
                                                Dedicated Account Manager
                                            </li>
                                        </ul>
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Button fullWidth variant="contained" color="primary">
                                        Contact Sales
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Feature Comparison Table */}
            <Box sx={{ padding: '80px 0', backgroundColor: 'secondary.main' }}>
                <Container>
                    <Typography variant="h4" component="h2" align="center" style={{ marginBottom: '40px', fontWeight: 'bold' }}>
                        Complete Feature Comparison
                    </Typography>
                    <Card >
                        <Table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <TableHead>
                                <TableRow style={{ borderBottom: '2px solid #e0e0e0' }}>
                                    <TableCell style={{ padding: '16px', textAlign: 'left' }}>Feature</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>Growth</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>Enterprise</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ padding: '16px' }}>Monthly Order Limit</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>300</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>Unlimited</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ padding: '16px' }}>Support</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>Email Support</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>24/7 Phone & Email</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ padding: '16px' }}>Response Time</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>24 Hours</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>2 Hours</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ padding: '16px' }}>Analytics</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>Basic</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>Advanced</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ padding: '16px' }}>Custom Reports</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>Limited</TableCell>
                                    <TableCell style={{ padding: '16px', textAlign: 'center' }}>Unlimited</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Card>
                </Container>
            </Box>

            {/* FAQ Section */}
            <Box style={{ padding: '80px 0' }}>
                <Box style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    <Typography variant="h4" component="h2" align="center" style={{ marginBottom: '40px', fontWeight: 'bold' }}>
                        Frequently Asked Questions
                    </Typography>
                    <Container>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" component="h3" style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                                    Can I upgrade or downgrade my plan?
                                </Typography>
                                <Typography variant="body1" style={{ color: '#757575' }}>
                                    Yes, you can change your plan at any time. Changes will be reflected in your next billing cycle.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" component="h3" style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                                    What happens if I exceed my order limit?
                                </Typography>
                                <Typography variant="body1" style={{ color: '#757575' }}>
                                    We'll notify you when you're approaching your limit. You can upgrade to the Enterprise plan for unlimited orders.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" component="h3" style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                                    Do you offer annual pricing?
                                </Typography>
                                <Typography variant="body1" style={{ color: '#757575' }}>
                                    Contact our sales team for special annual pricing and discounts.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" component="h3" style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                                    What payment methods do you accept?
                                </Typography>
                                <Typography variant="body1" style={{ color: '#757575' }}>
                                    We accept all major credit cards, UPI, and bank transfers.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>

            {/* Footer Section */}
            <Footer />

        </PageContainer>
    );
};

export default PricingPage;
