import { ArrowRight } from 'lucide-react';
import {
    Box,
    Container,
    Typography,
    Grid,
    Card,
    CardMedia,
    Button,
    Paper
} from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';
import { paths } from '../../paths';

const SuccessStories = () => {
    const stories = [
        {
            name: "Sharma Handicrafts",
            owner: "Priya Sharma",
            location: "Jaipur, Rajasthan",
            business: "Traditional Handicrafts & Home Decor",
            metrics: {
                growthRate: "143%",
                timesSaved: "15 hours/week",
                profitIncrease: "32%"
            },
            quote: "Virtacc helped us transform from a local artisan shop to a thriving online business. The automated accounting saved us countless hours and helped us focus on scaling our craft business.",
            challenge: "Managing complex marketplace fees and multiple payment reconciliations across different platforms.",
            solution: "Automated fee calculation and unified dashboard for all marketplace transactions."
        },
        {
            name: "NutriFit India",
            owner: "Arjun Patel",
            location: "Mumbai, Maharashtra",
            business: "Health Supplements & Nutrition Products",
            metrics: {
                growthRate: "215%",
                timesSaved: "20 hours/week",
                profitIncrease: "45%"
            },
            quote: "The real-time profit analytics helped us identify our most profitable products and optimize our inventory. Our profitability improved significantly within months.",
            challenge: "Tracking profitability across multiple SKUs and managing seasonal inventory fluctuations.",
            solution: "Product-level profit tracking and inventory analytics."
        },
        {
            name: "SareeHouse",
            owner: "Lakshmi Venkatesh",
            location: "Chennai, Tamil Nadu",
            business: "Traditional Sarees & Ethnic Wear",
            metrics: {
                growthRate: "167%",
                timesSaved: "25 hours/week",
                profitIncrease: "38%"
            },
            quote: "With Virtacc's multi-channel tracking, we could efficiently manage our sales across Amazon, Flipkart, and our own website. The automated reconciliation is a game-changer.",
            challenge: "Managing orders across multiple e-commerce platforms and reconciling payments.",
            solution: "Unified dashboard for multi-channel sales and automated payment reconciliation."
        },
        {
            name: "TechAccessories Hub",
            owner: "Rohit Mehta",
            location: "Bengaluru, Karnataka",
            business: "Mobile & Laptop Accessories",
            metrics: {
                growthRate: "189%",
                timesSaved: "18 hours/week",
                profitIncrease: "41%"
            },
            quote: "The detailed analytics helped us understand our customer segments better and optimize our advertising spend. Our ROI improved dramatically.",
            challenge: "High competition and need for precise profit margins tracking across numerous products.",
            solution: "Advanced analytics and competitive pricing insights."
        }
    ];

    const contactPage = () => { return window.location.href = paths.main.contactUs }

    return (
        <PageContainer title='Success Stories | Virtacc' description='This is Success Stories page of Virtacc'>

            <Box sx={{ minHeight: '100vh', backgroundColor: 'primary.contrastText' }}>

                {/* Navbar Section */}
                <Navbar backgroundColor='primary.main' />

                {/* Hero Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 12 }}>
                    <Container>
                        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
                            Success Stories
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ mt: 2, color: 'primary.contrastText' }}>
                            See how Indian e-commerce businesses are transforming with Virtacc
                        </Typography>
                    </Container>
                </Box>

                {/* Success Stories Grid */}
                <Container sx={{ maxWidth: 'lg', py: 16 }}>
                    {stories.map((story, index) => (
                        <Box
                            key={story.name}
                            sx={{
                                display: { sm: 'none', lg: 'flex', md: 'flex' },
                                flexDirection: index % 2 === 1 ? 'row-reverse' : 'row',
                                gap: 8,
                                mb: 16
                            }}
                        >
                            {/* Story Content */}
                            <Box sx={{ flex: 1, mb: 3 }}>
                                <Card sx={{ boxShadow: 3, borderRadius: 2, p: 4 }}>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                                        {story.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                                        {story.owner} • {story.location}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 4 }}>
                                        {story.business}
                                    </Typography>

                                    <Paper sx={{ borderLeft: 4, borderColor: 'primary.main', p: 2, mb: 4 }}>
                                        <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                            "{story.quote}"
                                        </Typography>
                                    </Paper>

                                    <Grid container spacing={4} sx={{ mb: 4 }}>
                                        <Grid item xs={4} textAlign="center">
                                            <Box sx={{ backgroundColor: 'secondary.dark', p: 3, borderRadius: 2 }}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                                                    {story.metrics.growthRate}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                    Revenue Growth
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} textAlign="center">
                                            <Box sx={{ backgroundColor: 'secondary.dark', p: 3, borderRadius: 2 }}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                                                    {story.metrics.timesSaved}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                    Time Saved
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} textAlign="center">
                                            <Box sx={{ backgroundColor: 'secondary.dark', p: 3, borderRadius: 2 }}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                                                    {story.metrics.profitIncrease}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                    Profit Increase
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ mb: 4 }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                            Challenge:
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            {story.challenge}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 4 }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                            Solution:
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            {story.solution}
                                        </Typography>
                                    </Box>
                                </Card>
                            </Box>

                            {/* Story Visualization */}
                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CardMedia
                                    component="img"
                                    sx={{ borderRadius: 2, maxHeight: 400 }}
                                    image={`/assets/images/placeholder/600x400.svg`}
                                    alt={`${story.name} success story 600x400`}
                                />
                            </Box>
                        </Box>
                    ))}
                </Container>

                {/* CTA Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 16, alignItems: 'center' }}>
                    <Box sx={{ textAlign: 'center', maxWidth: 'lg', margin: '0 auto' }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
                            Ready to Write Your Success Story?
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'primary.contrastText', mb: 8 }}>
                            Join these successful businesses and transform your e-commerce operations
                        </Typography>
                        <Button variant="contained" color='success' onClick={contactPage} sx={{ px: 4, py: 2, fontSize: '1rem' }} endIcon={<ArrowRight />} >
                            Start Your Journey
                        </Button>
                    </Box>
                </Box>

                {/* Footer Section */}
                <Footer />

            </Box>

        </PageContainer>
    );
};

export default SuccessStories;
