import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import type { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { SalesType } from '../../../interfaces/Amazon/ExSales';
import dayjs from 'dayjs';

export interface ExSalesProps {
  diff?: number | string;
  trend?: 'up' | 'down';
  sx?: SxProps;
  data: SalesType | null;
  interval: [dayjs.Dayjs | null, dayjs.Dayjs | null]
}

export function ExSales({ diff, trend, sx, interval, data }: ExSalesProps): React.JSX.Element {
  const TrendIcon = trend === 'up' ? ArrowUpwardIcon : ArrowDownwardIcon;
  const trendColor = trend === 'up' ? 'var(--mui-palette-success-main)' : 'var(--mui-palette-error-main)';

  const startDate = dayjs(interval[0]).format('YYYY-MM-DDTHH:mmZ');
  const endDate = dayjs(interval[1]).format('YYYY-MM-DDTHH:mmZ');

  if (data) {
    return (
      <Card sx={sx}>
        <CardContent>
          <Stack spacing={3}>
            <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
              <Stack spacing={1}>
                <Typography color="text.secondary">
                  Amazon Sales
                </Typography>
                <Typography color="text.secondary" variant="overline">
                  {dayjs(startDate).format('MMM D, YYYY')} - {dayjs(endDate).format('MMM D, YYYY')}
                </Typography>
                <Typography variant="h4">
                  {data.totalSales.currencyCode == 'INR' ? '₹' : ''} {new Intl.NumberFormat('en-IN').format(data.totalSales.amount)}
                </Typography>
                <Typography variant="overline" lineHeight={'1'}> Orders: {data.orderCount} </Typography>
                <Typography variant="overline" lineHeight={'1'}> Units: {data.unitCount} </Typography>
              </Stack>
              <Avatar sx={{ backgroundColor: 'var(--mui-palette-primary-main)', height: '56px', width: '56px' }}>
                <CurrencyRupeeIcon />
              </Avatar>
            </Stack>
            {diff ? (
              <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
                <Stack sx={{ alignItems: 'center' }} direction="row" spacing={0.5}>
                  <TrendIcon />
                  <Typography color={trendColor} variant="body2">
                    {diff}%
                  </Typography>
                </Stack>
                <Typography color="text.secondary" variant="caption">
                  Since last month
                </Typography>
              </Stack>
            ) : null}
          </Stack>
        </CardContent>
      </Card>
    );
  }
  else return (
    <Card sx={sx}>
      <CardContent>
        <Stack spacing={3}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="overline">
                Sales
              </Typography>
              <Typography color="text.secondary" variant="overline">
                {dayjs(startDate).format('MMM D, YYYY')} - {dayjs(endDate).format('MMM D, YYYY')}
              </Typography>
              <Typography variant="h4">
                {' - '}
              </Typography>
              <Typography variant="overline" lineHeight={'1'}> Orders: - </Typography>
              <Typography variant="overline" lineHeight={'1'}> Units: - </Typography>
            </Stack>
            <Avatar sx={{ backgroundColor: 'var(--mui-palette-primary-main)', height: '56px', width: '56px' }}>
              <CurrencyRupeeIcon />
            </Avatar>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );

}
