import * as React from 'react';
import {
  Box, Card, Chip, ChipProps, Divider, IconButton, Modal, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow,
  Typography
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import dayjs from 'dayjs';
import { SubscriptionsType } from '../../../interfaces/Subscriptions';
import { Close } from '@mui/icons-material';
import UpdateSubscriptionForm from './UpdateSubscriptonForm';

export interface SubscriptionsProps {
  sx?: SxProps;
  subscriptions?: SubscriptionsType[];
}

const statusMap: Record<string, { label: string, color: ChipProps['color'] }> = {
  true: { label: 'Active', color: 'success' },
  false: { label: 'Inactive', color: 'error' },
};

const SubscriptionsTable: React.FC<SubscriptionsProps> = ({ subscriptions = [], sx }) => {

  // PAGINATION { start }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredSubscriptions, setFilteredSubscriptions] = React.useState<SubscriptionsType[]>(subscriptions);
  const [editIndex, setEditIndex] = React.useState(-1);
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedSubscription, setSelectedSubscription] = React.useState<SubscriptionsType | null>(null);

  React.useEffect(() => {
    setFilteredSubscriptions(subscriptions);
  }, [subscriptions]);

  // Handle edit button click
  const handleEditClick = (index: number, subscription: SubscriptionsType) => {
    setEditIndex(index);
    handleModalOpen();
    setSelectedSubscription(subscription);
  };

  // Handle Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedSubscriptions = filteredSubscriptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // PAGINATION { end }

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => {
    setOpen(false);
    setEditIndex(-1); // Exit edit mode
  }
  const handleFormSuccess = () => {
    handleModalClose(); // Close the modal
    window.location.reload();
  };
  const styleModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',  // Ensure the modal takes 90% width on smaller screens
    maxWidth: 600, // Set a maximum width for large screens
    bgcolor: 'background.paper',
    border: '1px solid var(--mui-palette-primary-main)',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh', // Ensure the modal doesn't exceed the screen height (80% of viewport height)
    overflowY: 'auto', // Allow vertical scrolling when content overflows
    '::-webkit-scrollbar': {
      width: '0px',
    },
  };
  const renderModal = (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-add-organization"
      aria-describedby="modal-add-organization-description"
    >
      <Stack sx={styleModal}>
        <Stack direction="row" spacing={3}>
          <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
            <Typography sx={{ mb: 3 }} variant="h5">Update Subscription</Typography>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{ position: 'absolute', right: 20 }}
          >
            <Close />
          </IconButton>
        </Stack>
        <UpdateSubscriptionForm onSuccess={handleFormSuccess} subscription={selectedSubscription} />
      </Stack>
    </Modal>
  );

  return (
    <Card sx={sx}>
      <Box sx={{ overflowX: 'auto' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Organization</TableCell>
              <TableCell>Key</TableCell>
              <TableCell>Users</TableCell>
              <TableCell>Starts</TableCell>
              <TableCell>Expires</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              paginatedSubscriptions.length > 0 ? (
                paginatedSubscriptions.map((subscription, index) => {
                  const { label, color } = statusMap[subscription.is_active.toString()] ?? { label: 'Unknown', color: 'default' };
                  return (
                    <TableRow hover key={subscription.organization}>
                      <TableCell>{subscription.organization}</TableCell>
                      <TableCell>{subscription.subscription_key}</TableCell>
                      <TableCell>{subscription.no_of_users}</TableCell>
                      <TableCell>{dayjs(subscription.starts_at).format('MMM D, YYYY')}</TableCell>
                      <TableCell>{dayjs(subscription.expire_at).format('MMM D, YYYY')}</TableCell>
                      <TableCell>{dayjs(subscription.created_at).format('MMM D, YYYY')}</TableCell>
                      <TableCell>{dayjs(subscription.updated_at).format('MMM D, YYYY')}</TableCell>
                      <TableCell>
                        <Chip color={color} label={label} variant='outlined' style={{ cursor: 'pointer' }} />
                      </TableCell>
                      <TableCell>
                        {
                          editIndex === index ? (
                            <Chip color={'success'} label={'Save'} />
                          ) : (
                            <Chip color={'default'} label={'Edit'} onClick={() => handleEditClick(index, subscription)} />
                          )
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                    No data available
                  </TableCell>
                </TableRow>
              )
            }
            {renderModal}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <TablePagination
        component="div"
        count={filteredSubscriptions.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

export default SubscriptionsTable;