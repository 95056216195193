import dayjs, { Dayjs } from "dayjs";
import { OrdersType } from "../../../interfaces/Orders";
import { ppcType } from "../../../interfaces/PPC";
import React, { useMemo } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { formatNumber } from "../../../utils/format";

interface PPCChart01Props {
    ppcData: ppcType[];
    ordersData: OrdersType[];
    dateRange: [Dayjs | null, Dayjs | null];
}

const PPCChart01: React.FC<PPCChart01Props> = ({ ppcData, ordersData, dateRange }) => {
    // Filter data based on the selected date range
    const filteredPpcData = useMemo(() => {
        if (ppcData.length > 0) {
            return ppcData.filter((item) => {
                const itemDate = dayjs(item.date); // Assuming item.date is in a string format like 'YYYY-MM-DD'
                return itemDate.isBetween(dateRange[0], dateRange[1], null, '[]');
            });
        }
        return []
    }, [ppcData, dateRange]);

    const filteredOrdersData = useMemo(() => {
        if (ordersData.length > 0) {
            return ordersData.filter((item) => {
                const itemDate = dayjs(item.OrderDate);
                return itemDate.isBetween(dateRange[0], dateRange[1], null, '[]')
            });
        }
        return []
    }, [ordersData, dateRange]);

    // Aggregate data by date
    const formattedData = useMemo(() => {
        const aggregationMap = new Map<string, { sales: number; skuSold: number; spend: number }>();

        filteredPpcData.forEach((data) => {
            const dateKey = data.date;
            const currentData = aggregationMap.get(dateKey) || { sales: 0, skuSold: 0, spend: 0 };

            aggregationMap.set(dateKey, {
                sales: currentData.sales + formatNumber(data.sales1d),
                skuSold: currentData.skuSold + filteredOrdersData.length,
                spend: currentData.spend + formatNumber(data.spend),
            });
        });

        return Array.from(aggregationMap.entries()).map(([date, { sales, skuSold, spend }]) => ({
            date,
            sales,
            skuSold,
            spend,
        }));
    }, [filteredPpcData, filteredOrdersData]);

    return (
        <React.Fragment>
            <Card sx={{ height: '100%' }}>
                <CardHeader>{'Sales and Spend'}</CardHeader>
                <CardContent>
                    <ResponsiveContainer width="100%" height={400}>
                        <ComposedChart data={formattedData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="sales" barSize={20} fill="#8884d8" />
                            <Line type="monotone" dataKey="skuSold" stroke="#ff7300" />
                            <Line type="monotone" dataKey="spend" stroke="#82ca9d" />
                        </ComposedChart>
                    </ResponsiveContainer>
                    Total Orders: {filteredOrdersData.length}
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default PPCChart01;
