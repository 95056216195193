import { getCustomHeaders } from "./HeadersUtility";

export const CustomFetch = async (
  url: string,
  options: RequestInit = {}
): Promise<Response> => {

  const headers = getCustomHeaders();

  // Merge additional headers from options
  if (options.headers) {
    const additionalHeaders = new Headers(options.headers);
    additionalHeaders.forEach((value, key) => {
      headers.set(key, value);
    });
  }

  const customOptions: RequestInit = {
    ...options,
    headers,
  };

  const response = await fetch(url, customOptions);

  // if (!response.ok) {
  //   // Handle errors globally if needed
  //   throw new Error(`HTTP error! status: ${response.status}`);
  // }

  return response;
};
