import { Box, Card, Chip, ChipProps, Divider, IconButton, Modal, Stack, SxProps, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import React from "react";
import { UsersType } from "../../../../interfaces/Users";
import dayjs from "dayjs";
import { Close } from "@mui/icons-material";
import UpdateUserForm from "./UpdateUserForm";
import { userDataPayload } from "../../../../interfaces/UserPayload";

interface UsersTableProps {
    sx?: SxProps;
    users?: UsersType[];
    currentUser: userDataPayload | null
}

const statusMap: Record<string, { label: string, color: ChipProps['color'] }> = {
    true: { label: 'Active', color: 'success' },
    false: { label: 'Inactive', color: 'error' },
};

const UsersTable: React.FC<UsersTableProps> = ({ sx, users = [], currentUser }) => {

    // PAGINATION { start }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const [filteredUsers, setFilteredUsers] = React.useState<UsersType[]>(users);
    const [modelOpen, setModelOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<UsersType | null>(null);

    // Handle Pagination
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedUsers = users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    // PAGINATION { end }

    // Handle edit button click
    const handleEditClick = (user: UsersType) => {
        handleModalOpen();
        setSelectedUser(user);
    };
    const handleModalOpen = () => setModelOpen(true);
    const handleModalClose = () => setModelOpen(false);
    const handleFormSuccess = () => {
        handleModalClose(); // Close the modal
        window.location.reload();
    };
    const styleModal = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',  // Ensure the modal takes 90% width on smaller screens
        maxWidth: 600, // Set a maximum width for large screens
        bgcolor: 'background.paper',
        border: '1px solid var(--mui-palette-primary-main)',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4,
        maxHeight: '80vh', // Ensure the modal doesn't exceed the screen height (80% of viewport height)
        overflowY: 'auto', // Allow vertical scrolling when content overflows
        '::-webkit-scrollbar': {
            width: '0px',
        },
    };
    const renderModal = (
        <Modal
            open={modelOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-add-organization"
            aria-describedby="modal-add-organization-description"
        >
            <Stack sx={styleModal}>
                <Stack direction="row" spacing={3}>
                    <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
                        <Typography sx={{ mb: 3 }} variant="h5">Update User</Typography>
                    </Stack>
                    <IconButton
                        aria-label="close"
                        onClick={handleModalClose}
                        sx={{ position: 'absolute', right: 20 }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <UpdateUserForm onSuccess={handleFormSuccess} user={selectedUser} />
            </Stack>
        </Modal>
    );

    return (
        <Card sx={sx}>
            <Box sx={{ overflowX: 'auto' }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            {/* <TableCell>First Name</TableCell> */}
                            {/* <TableCell>Last Name</TableCell> */}
                            <TableCell>Email</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Status</TableCell>
                            {
                                currentUser?.role === '1' || currentUser?.role === '0' ? (
                                    <TableCell>Action</TableCell>
                                ) : (<></>)
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            paginatedUsers.length > 0 ? (
                                paginatedUsers.map((user, index) => {
                                    const { label, color } = statusMap[user.is_active.toString()] ?? { label: 'Unknown', color: 'default' };
                                    return (
                                        <TableRow hover key={user.id}>
                                            <TableCell>{user.username}</TableCell>
                                            {/* <TableCell>{user.first_name}</TableCell> */}
                                            {/* <TableCell>{user.last_name}</TableCell> */}
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.mobile_number}</TableCell>
                                            <TableCell>{(user.role === '1') ? 'Admin' : 'User'}</TableCell>
                                            <TableCell>{dayjs(user.createdAt).format('MMM D, YYYY')}</TableCell>
                                            <TableCell><Chip color={color} label={label} variant='outlined' /></TableCell>
                                            {
                                                currentUser?.role === '1' || currentUser?.role === '0' ? (
                                                    <TableCell>
                                                        <Chip color={'default'} label={'Edit'} onClick={() => handleEditClick(user)} />
                                                    </TableCell>
                                                ) : (<></>)
                                            }
                                        </TableRow>
                                    )
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                {renderModal}
            </Box>
            <Divider />
            <TablePagination
                component="div"
                count={users.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Card>
    );
};

export default UsersTable;