import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ppcType } from '../../../../interfaces/PPC';

interface SBarChartProps {
    ppcData: ppcType[]; // Array of ppcType data to pass to the chart
}

// Purpose: Compare multiple metrics (e.g., clicks, impressions, spends) for different campaignId or advertisedSku.
// Data: You would stack different metrics (e.g., spends and clicks) on top of each other for the same campaign or SKU.
// Chart Type: Stacked Bar Chart
// Example: Stack clicks and spends for each SKU.

const SBarChart: React.FC<SBarChartProps> = ({ ppcData = [] }) => {

    const chartData = Array.isArray(ppcData) && ppcData.length > 0
        ? ppcData.reduce((acc, item) => {
            const existing = acc.find((data) => data.advertisedSku === item.advertisedSku);
            if (existing) {
                // Accumulate the values for existing dates
                existing.spends += item.spend;
                existing.clicks += item.clicks;
            } else {
                // Create a new entry for the date
                acc.push({
                    advertisedSku: item.advertisedSku,
                    spends: item.spend,
                    clicks: item.clicks,
                });
            }
            return acc;
        }, [] as { advertisedSku: string; spends: number; clicks: number }[])
        : [];

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="advertisedSku" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="clicks" stackId="a" fill="#8884d8" />
                <Bar dataKey="spends" stackId="a" fill="#82ca9d" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default SBarChart;