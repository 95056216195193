import { Container, Box, Typography, Card, CardContent } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';
import { config } from '../../config';

const TermsOfServicePage = () => {
    return (
        <PageContainer title='Terms of Service | Virtacc' description='This is Terms of Service page of Virtacc'>

            <Box sx={{ minHeight: '100vh', backgroundColor: 'primary.contrastText' }}>

                {/* Navbar Section */}
                <Navbar backgroundColor='primary.main' />

                {/* Hero Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 12 }}>
                    <Container>
                        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
                            Terms of Service
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ mt: 2, color: 'primary.contrastText' }}>
                            Last Updated: December 20, 2024
                        </Typography>
                    </Container>
                </Box>

                {/* Content Section */}
                <Container sx={{ py: 16 }}>
                    <Box sx={{ mx: 'auto' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            1. Acceptance of Terms
                        </Typography>
                        <Typography paragraph>
                            By accessing and using Virtacc's services ("Service"), you agree to be bound by these Terms of Service
                            ("Terms"), which constitute a legal agreement between you and Virtacc Technologies Private Limited
                            ("Virtacc", "we", "us", or "our"). These Terms are governed by Indian law, particularly the Information
                            Technology Act, 2000, and related rules and regulations.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            2. Service Description
                        </Typography>
                        <Typography paragraph>
                            Virtacc provides e-commerce accounting and analytics services through its web-based platform. The Service
                            includes features for financial tracking, reporting, and analysis of e-commerce operations. We reserve
                            the right to modify, suspend, or discontinue any part of the Service at any time.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            3. Account Registration
                        </Typography>
                        <Typography paragraph>
                            To use our Service, you must register for an account. You agree to provide accurate, current, and complete
                            information during registration and to update such information to keep it accurate, current, and complete.
                            You are responsible for safeguarding your account credentials and for any activities under your account.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            4. Subscription and Payments
                        </Typography>
                        <ul>
                            <li>Subscription fees are billed in advance on a monthly basis</li>
                            <li>All payments shall be made in Indian Rupees (INR)</li>
                            <li>Applicable GST and other taxes will be charged additionally</li>
                        </ul>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            5. Refunds and Cancellations
                        </Typography>
                        <Typography paragraph>
                            All payments for services provided by Virtacc are non-refundable. By subscribing to or purchasing our services, you acknowledge and agree that no refunds will be issued under any circumstances, including but not limited to, cancellation of the service, non-use of the service, or dissatisfaction with the service.
                        </Typography>
                        <Typography paragraph>
                            Once a payment has been processed, it is considered final, and you are not entitled to a refund or cancellation. We encourage you to review our offerings thoroughly and ensure that our services meet your needs before making a commitment.
                        </Typography>
                        <Typography paragraph>
                            In the event of a service discontinuation or account termination by Virtacc for violations of the Terms of Service, no refunds will be issued for any unused portion of the subscription.
                        </Typography>
                        <Typography paragraph>
                            Please contact us if you have any questions or concerns before subscribing to our service.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            6. Data Security and Privacy
                        </Typography>
                        <Typography paragraph>
                            We implement reasonable security practices and procedures as mandated by the Information Technology
                            (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            7. User Obligations
                        </Typography>
                        <Typography paragraph>
                            You agree not to:
                        </Typography>
                        <ul>
                            <li>Use the Service for any illegal purpose or in violation of any laws</li>
                            <li>Attempt to gain unauthorized access to the Service or its systems</li>
                            <li>Interfere with or disrupt the integrity or performance of the Service</li>
                            <li>Share your account credentials with third parties</li>
                        </ul>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            8. Limitation of Liability
                        </Typography>
                        <Typography paragraph>
                            To the maximum extent permitted by law, Virtacc shall not be liable for any indirect, incidental,
                            special, consequential, or punitive damages, or any loss of profits or revenues.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            9. Termination
                        </Typography>
                        <Typography paragraph>
                            We may terminate or suspend your access to the Service immediately, without prior notice or liability,
                            for any reason whatsoever, including without limitation if you breach the Terms.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            10. Governing Law
                        </Typography>
                        <Typography paragraph>
                            These Terms shall be governed by and construed in accordance with the laws of India. Any disputes shall be
                            subject to the exclusive jurisdiction of courts in New Delhi, India.
                        </Typography>

                        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                            11. Changes to Terms
                        </Typography>
                        <Typography paragraph>
                            We reserve the right to modify these Terms at any time. We will notify you of any material changes via
                            email or through the Service.
                        </Typography>
                    </Box>

                    {/* Contact Section */}
                    <Card sx={{ mt: 12, p: 3, backgroundColor: 'secondary.main' }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Contact Us
                            </Typography>
                            <Typography paragraph>
                                If you have any questions about these Terms, please contact us at:
                            </Typography>
                            <Typography>Email: <a href={`mailto:${config.legalMail}`} style={{ textDecoration: 'none' }}>{config.legalMail}</a></Typography>
                            <Typography>Address: {config.address.full}</Typography>
                        </CardContent>
                    </Card>
                </Container>

                {/* Footer Section */}
                <Footer />
            </Box>

        </PageContainer>
    );
};

export default TermsOfServicePage;
