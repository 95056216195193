import React, { ChangeEvent, FormEvent, useState } from 'react';
import {
    Clock,
    User,
    ArrowRight,
    BookOpen
} from 'lucide-react';
import { Box, Container, Grid, Typography, Button, Paper, Chip, TextField } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
import PageContainer from '../../components/PageContainer/PageContainer';
import mailService from './api/SendMail.api';
import { toast } from 'react-toastify';
import { newsletter } from '../../interfaces/SendMail';

// Define the types for Blog
interface Blog {
    title: string;
    excerpt: string;
    author: string;
    role: string;
    date: string;
    readTime: string;
    category: string;
    content: string;
    image: string;
}

const BlogPage: React.FC = () => {
    const blogs: Blog[] = [
        {
            title: "Maximizing Profitability: A Guide to Multi-Channel E-commerce",
            excerpt: "Learn how to effectively manage and optimize profits across multiple e-commerce platforms.",
            author: "Priya Sharma",
            role: "E-commerce Strategy Consultant",
            date: "December 15, 2024",
            readTime: "10 min read",
            category: "Strategy",
            content: `Managing multiple e-commerce channels effectively requires a strategic approach to maximize profitability. Here's a comprehensive guide:

                Understanding Channel Economics
                - Platform-specific fee structures
                - Marketing costs per channel
                - Fulfillment expenses
                - Customer acquisition costs

                Inventory Management Across Channels
                - Centralized inventory tracking
                - Channel-specific stock allocation
                - Reorder point optimization
                - Dead stock prevention

                Pricing Strategy
                - Channel-based pricing
                - Competitor analysis
                - Margin optimization
                - Dynamic pricing implementation

                Performance Analytics
                - Channel comparison metrics
                - Unified reporting
                - Profit analysis by channel
                - Growth optimization

                Technology Integration
                - ERP systems
                - Order management
                - Accounting software
                - Analytics tools`,
            image: "/assets/images/placeholder/800x400.svg"
        },
        {
            title: "The Future of E-commerce Accounting: AI and Automation Trends for 2025",
            excerpt: "Exploring how artificial intelligence and automation are transforming e-commerce accounting practices.",
            author: "Amit Patel",
            role: "Technology Innovation Director",
            date: "December 10, 2024",
            readTime: "12 min read",
            category: "Technology",
            content: `The e-commerce accounting landscape is rapidly evolving with AI and automation leading the charge. Here's what to expect in 2025:

                Artificial Intelligence in E-commerce Accounting
                - Real-time fraud detection
                - Automated categorization
                - Predictive analytics
                - Smart reconciliation

                Automation Advancements
                - End-to-end transaction processing
                - Intelligent cash flow forecasting
                - Automated compliance checks
                - Smart inventory management

                Integration Capabilities
                - Cross-platform data synthesis
                - Real-time reporting
                - Automated tax calculations
                - Multi-currency management

                Impact on Business Operations
                - Reduced manual operations
                - Enhanced accuracy
                - Faster decision-making
                - Improved compliance

                Preparing for the Future
                - Skill development needs
                - Technology adoption strategies
                - Implementation roadmap
                - Team training considerations`,
            image: "/assets/images/placeholder/800x400.svg"
        }
    ];

    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<newsletter>({ email: '' });

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await mailService.sendMailNewsletter(formData);
            if (response.data) {
                toast.success(response.message);
                setFormData({ email: '' }); // Clear the form data
            } else {
                toast.error(response.message);
                setFormData({ email: '' }); // Clear the form data
            }
            setLoading(false);
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
            setLoading(false);
        }
    };

    return (
        <PageContainer title='Blogs | Virtacc' description='This is Blogs page of Virtacc'>

            <Box style={{ minHeight: '100vh', backgroundColor: 'secondary.dark' }}>

                {/* Navbar Section */}
                <Navbar backgroundColor='primary.main' />

                {/* Hero Section */}
                <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText', py: 12 }}>
                    <Container>
                        <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Virtacc Blog
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ color: 'primary.contrastText', maxWidth: 900, mx: 'auto' }}>
                            Insights and guides for e-commerce success
                        </Typography>
                    </Container>
                </Box>

                {/* Blog Posts */}
                <Container sx={{ py: 6 }}>
                    <Grid container spacing={4}>
                        {blogs.map((blog, index) => (
                            <Grid item xs={12} md={6} key={blog.title}>
                                <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 2 }}>
                                    <Box sx={{ flex: 1 }}>
                                        <img
                                            src={blog.image}
                                            alt={blog.title + ' 800x400'}
                                            style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '8px' }}
                                        />
                                    </Box>
                                    <Box sx={{ flex: 2, ml: index % 2 === 1 ? 2 : 0 }}>
                                        <Chip label={blog.category} color="primary" sx={{ mb: 2 }} />
                                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                                            {blog.title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
                                            {blog.excerpt}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <User size={40} style={{ marginRight: 8 }} />
                                            <Typography variant="body2" sx={{ mr: 4 }}>
                                                {blog.author} - {blog.role}
                                            </Typography>
                                            <Clock size={40} style={{ marginRight: 8 }} />
                                            <Typography variant="body2" sx={{ mr: 4 }}>
                                                {blog.readTime}
                                            </Typography>
                                            <BookOpen size={40} style={{ marginRight: 8 }} />
                                            <Typography variant="body2">{blog.date}</Typography>
                                        </Box>
                                        <Button variant="outlined" color="success" endIcon={<ArrowRight />} sx={{ fontWeight: 'bold' }}>
                                            Read More
                                        </Button>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Container>

                {/* Subscribe Section */}
                <Box sx={{ backgroundColor: 'secondary.main', py: 6 }}>
                    <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Stay Updated
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
                            Get the latest e-commerce insights and tips delivered to your inbox
                        </Typography>
                        <form onSubmit={handleSubmit} >
                            <Box sx={{ display: 'flex', flexDirection: 'column', sm: 'row', gap: 2, justifyContent: 'center' }}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    placeholder="Enter your email"
                                    fullWidth
                                    sx={{ borderRadius: 2 }}
                                    onChange={onChange}
                                />
                                <Button type='submit' variant="contained" color="success" sx={{ px: 4, py: 2 }} disabled={loading}>
                                    Subscribe
                                </Button>
                            </Box>
                        </form>

                    </Container>
                </Box>

                {/* Categories */}
                <Container maxWidth="lg" sx={{ py: 6 }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 4 }}>
                        Browse by Category
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button variant="outlined" color="success" fullWidth>
                                Accounting
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button variant="outlined" color="success" fullWidth>
                                Strategy
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button variant="outlined" color="success" fullWidth>
                                Technology
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button variant="outlined" color="success" fullWidth>
                                Growth
                            </Button>
                        </Grid>
                    </Grid>
                </Container>

                {/* Footer Section */}
                <Footer />

            </Box>

        </PageContainer>
    );
};

export default BlogPage;
